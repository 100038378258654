import { Pipe, PipeTransform } from '@angular/core';
import { Roles } from '../_interface';

@Pipe({
    name: 'AdminRole'
})
export class AdminRole implements PipeTransform {
    transform(value: any): any {
        return Roles.filter((item: any) => item.id == value)[0]?.title;
    }
}