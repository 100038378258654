export enum API {
    SEND_LOGIN_OTP = 'public/user/login/otp',
    LOGIN = 'public/user/login/otp/verify',

    SEND_AADHAR_OTP = 'public/user/register/adhar',
    VERIFY_AADHAR_OTP = 'public/user/adhaar/otp/verify',
    SAVE_ADDHAR_PAN_INFO = 'public/user/adhar/detail/add',
    SAVE_PERSONAL_INFO = 'public/user/personal-detail/add',
    SAVE_PATIENT_DETAILS = 'public/user/patient/detail/add',
    SAVE_TREATMENT_DETAILS = 'public/user/treatment/detail/add',
    SAVE_INSURANCE_DETAILS = 'public/user/insurance/detail/add',
    SAVE_PERSONAL_INFO_FOR_CIBIL = 'api/wati/user/info/add',
    WP_JOURNEY_DOC_NOT_VALID = 'public/wp_journey/document_not_valid',

    SEND_AADHAR_NO_OTP = 'public/user/adhaar_verify',

    SAVE_ADDHAR_PAN = 'public/hospital-admin/register/user',
    SAVE_PATIENT_TREATMENT_INSUREANCE = 'public/user/patient-treatment-insurance/save',

    USER_GET = 'api/user/get',
    USER_DELETE = 'api/user/delete',
    VALIDATE_USER = 'public/validate/user',

    HOSPITAL_GET = 'public/hospital/get',
    HOSPITAL_SAVE = 'api/hospital/save',
    HOSPITAL_DELETE = 'api/hospital/delete',
    HOSPITAL_EXPORT = 'api/hospital/excel/generate',

    HOSPITAL_DETAILS_GET = 'public/hospital_detail/get',
    HOSPITAL_DETAILS_SAVE = 'api/hospital_detail/save',
    HOSPITAL_DETAILS_DELETE = 'api/hospital_detail/delete',

    HOSPITAL_MCI_VERIFICATION = 'api/mci-verification',
    HOSPITAL_INQUIRY_GET = 'public/hospital/inquiry/get',

    TREATMENT_TYPE_GET = 'public/treatment-type/get',
    TREATMENT_TYPE_SAVE = 'api/treatment-type/save',
    TREATMENT_TYPE_DELETE = 'api/treatment-type/delete',

    TREATMENT_GET = 'public/treatment/get',
    TREATMENT_SAVE = 'api/treatment/save',
    TREATMENT_DELETE = 'api/treatment/delete',

    PAYMENT_TERMS_SAVE = 'api/payment-terms/save',
    PAYMENT_TERMS_GET = 'api/payment-terms/get',
    PAYMENT_TERMS_DELETE = 'api/payment-terms/delete',

    LOAN_DETAIL_GET = 'api/loan_application/get',

    SAVE_SIGN_DOCS = 'api/sign-docs/save',
    SEND_OTP_FOR_SIGN_DOCS = 'api/sign-docs/send-otp',
    VERIFY_OTP_FOR_SIGN_DOCS = 'api/sign-docs/verify-otp',

    // ADMIN API
    ADMIN_SAVE = 'api/admin/save',
    ADMIN_GET = 'api/admin/get',
    ADMIN_DELETE = 'api/admin/delete',

    ADMIN_LOGIN = 'public/admin/login',

    // Loan application related API
    UPDATE_LOAN_APPLICATION = 'api/loan_application/save',
    CHANGE_STATUS = 'api/loan-application/status',
    SAVE_CLAIM_DOCUMENTS = 'api/claim_docs/save',
    SAVE_NEEDED_DOCUMENTS = 'api/needed_document/save',

    ADMIN_PATIENT_CHAT_GET = 'api/admin-patient/chat/get',
    ADMIN_PATIENT_CHAT_SAVE = 'api/admin-patient/chat/save',
    CHAT_NOTIFICATION = 'api/admin-patient/unread/message/count',

    DOCUMENTS_TYPE_GET = 'public/document-type/get',
    DOCUMENTS_TYPE_SAVE = 'api/document-type/save',
    DOCUMENTS_TYPE_DELETE = 'api/document-type/delete',

    // Castler
    CREATE_ESCROW_AC = 'api/account/create',
    GET_ESCROW_AC = 'api/account/detail/get',
    TRANSFER_AMOUNT = 'api/transer/amount',

    // NBFC and DOCTOR FLOW
    SAVE_TRANSACTION = 'api/nbfc_transaction/save',

    // SCOREME
    PAN_VERIFICATION = 'public/user/pancard/verify',
    CIBIL_VERIFY = 'public/creditbureau/verify',
    CIBIL_OTP_VERIFY = 'public/creditbureau/verify/otp',
    CIBIL_DOWNLOAD = 'public/creditbureau/file/get',
    CIBIL_STATUS_SAVE = 'api/cibil_status/save',
    CIBIL_STATUS_GET = 'public/cibil_status/get',
}
