import { Component, Input } from '@angular/core';
import { CommonService } from '../../_services';

@Component({
  selector: 'app-view-download-file',
  templateUrl: './view-download-file.component.html',
  styleUrls: ['./view-download-file.component.scss']
})
export class ViewDownloadFileComponent {
  @Input() file: any;

  constructor(public commonService: CommonService) { }
}
