import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GoogleCloud, StorageKey } from '../_constants';
import { CommonService } from '../_services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newRequest = request;
    let token = this.commonService.getLocalStorage(StorageKey.FRONT_TOKEN);
    if (this.router.url.search('/admin') !== -1) {
      token = this.commonService.getLocalStorage(StorageKey.ADMIN_TOKEN);
    }

    if (request.url.search(GoogleCloud) !== -1) {
      token = '';
    }

    if (token) {
      newRequest = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }
    return next.handle(newRequest);
  }
}
