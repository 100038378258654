<div *ngIf="files" class="p-3">
    <ng-container *ngIf="
    files.toLowerCase().includes('.jpg') || 
    files.toLowerCase().includes('.jpeg') || 
    files.toLowerCase().includes('.png')">
        <a [href]="files" data-toggle="lightbox" data-gallery="gallery">
            <img [src]="files" [width]="fileSize.width" [height]="fileSize.height" />
        </a>
    </ng-container>

    <ng-container *ngIf="
!files.toLowerCase().includes('.jpg') && 
!files.toLowerCase().includes('.jpeg') && 
!files.toLowerCase().includes('.png')">
        <a [href]="files" data-toggle="lightbox" data-gallery="gallery">
            <app-view-pdf [pdfUrl]="files" [pdfConfig]="pdfConfig"></app-view-pdf>
        </a>
    </ng-container>
</div>