import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ChatService } from '../../../_services';

@Component({
  selector: 'app-video-frame',
  templateUrl: './video-frame.component.html',
  styleUrls: ['./video-frame.component.scss']
})

export class VideoFrameComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('videoElement') videoElement: any;
  @Input() mediaStream: any;
  @Input() showControls: boolean = true;
  @Input() data: any;
  @Output() onCallCut: EventEmitter<any> = new EventEmitter<any>();

  public micMuted = false;
  public vidMuted = false;

  constructor(
    private chatService: ChatService
  ) { }

  ngOnChanges(): void {
    this.setStream();
  }

  ngAfterViewInit() {
    this.setStream();
  }

  ngOnInit() { }

  private setStream() {
    if (
      !this.mediaStream ||
      !this.videoElement ||
      !this.videoElement.nativeElement
    ) {
      return;
    }
    this.videoElement.nativeElement.srcObject = this.mediaStream;
    this.videoElement.nativeElement.play();
  }

  muteMic() {
    const [audio] = this.mediaStream.getAudioTracks();
    this.micMuted = !(audio.enabled = !audio.enabled);
  }

  disableCam() {
    const [video] = this.mediaStream.getVideoTracks();
    this.vidMuted = !(video.enabled = !video.enabled);
  }

  endCall() {
    let receiver_id: any = this.data.admin_uid;
    if (this.data.flag) {
      receiver_id = this.data.id;
    }
    this.chatService.actionOnVideoKycCall({
      ...this.data,
      receiver_id,
      isAcceptCall: 0
    });

    setTimeout(() => {
      this.onCallCut.emit(0);
    }, 1000);
  }
}
