import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, FAICONS } from '../../_constants';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() btnConfig = { isShowAdd: true };
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter<any>();
  actions: any = Actions;
  faIcon: any = FAICONS;
  pageTitle: string = '';


  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.pageTitle = this.activatedRoute.snapshot.data['title'];
  }
}
