import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { API } from '../_constants';
import { HttpClientService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class LoanService {

    constructor(
        private httpClientService: HttpClientService
    ) { }


    /**
     * Save chat
     * @param request request for save messages
     * @returns res from api
     */
    adminPatientChatSave(request: any = {}) {
        let formData: any = new FormData();

        if (request.read_msg_id && request.read_msg_id.length > 0) {
            formData.append('read_msg_id', JSON.stringify(request.read_msg_id));
        } else if (request.loan_id) {
            formData.append('loan_id', String(request.loan_id));
            formData.append('sender_id', String(request.sender_id));
            formData.append('receiver_id', String(request.receiver_id));
            formData.append('message', request.message);

            if (request.documents && request.documents.length > 0) {
                request.documents.forEach((file: any) => {
                    formData.append('documents', file);
                });
            }
        }

        const url = environment.endpoint + API.ADMIN_PATIENT_CHAT_SAVE;
        return this.httpClientService.post(url, formData);
    }

    /**
     * Get chat
     * @param request request to get messages
     * @returns res from api
     */
    adminPatientChatGet(request: any) {
        const url = environment.endpoint + API.ADMIN_PATIENT_CHAT_GET;
        return this.httpClientService.post(url, request);
    }

    /**
     * Get chat
     * @param request request to get messages
     * @returns res from api
     */
    chatNotificationGet(request: any) {
        const url = environment.endpoint + API.CHAT_NOTIFICATION;
        return this.httpClientService.post(url, request);
    }

    /**
     * Change loan application status
     * 0 PENDING
     * 1 APPROVED
     * 2 REJECTED
     * 3 ESCALATED
     * @param request request to change status
     * @returns res from api
     */
    changeLoanStatus(request: any) {
        const url = environment.endpoint + API.CHANGE_STATUS;
        return this.httpClientService.post(url, request);
    }

    /**
     * Update loan application fields
     * @param request request data
     * @returns res from api
     */
    updateLoanApplication(request: any) {
        const url = environment.endpoint + API.UPDATE_LOAN_APPLICATION;
        return this.httpClientService.post(url, request);
    }

    /**
     * Sign documents
     * @param request request data
     * @returns res from api
     */
    uploadDocsForSign(request: any) {
        let formData: any = new FormData();
        formData.append('file', request);
        const url = environment.endpoint + API.SAVE_SIGN_DOCS;
        return this.httpClientService.post(url, formData);
    }

    /**
     * Send otp for sign docs
     * @param request request data
     * @returns res from api
     */
    sendOTPForSignDocs(request: any) {
        const url = environment.endpoint + API.SEND_OTP_FOR_SIGN_DOCS;
        return this.httpClientService.post(url, request);
    }

    /**
     * Verify otp for sign docs
     * @param request request data
     * @returns res from api
     */
    verifyOTPForSignDocs(request: any) {
        const url = environment.endpoint + API.VERIFY_OTP_FOR_SIGN_DOCS;
        return this.httpClientService.post(url, request);
    }

    /**
     * Get loan details
     * @param request request data
     * @returns res from api
     */
    getLoanDetails(request?: any) {
        let url = environment.endpoint + API.LOAN_DETAIL_GET;
        if (request?.paginate) {
            url += `?currentPage=${request.paginate.currentPage}&pageSize=${request.paginate.pageSize}`;

            if (request.keyword) {
                url += `&keyword=${request.keyword}`;
            }
            url += `&application_status=${request.application_status}`;
        } else if (request?.application_status || request?.application_status === 0) {
            url += `?application_status=${request.application_status}`;
        }

        if (request?.id) {
            if (!request.application_status) {
                url += `?id=${request.id}`;
            } else { url += `&id=${request.id}`; }
        }

        return this.httpClientService.get(url);
    }

    /**
     * Delete application
     * @param request req params
     * @returns res from API
     */
    deleteApplicatins(request: any) {
        const url = environment.endpoint + API.USER_DELETE;
        return this.httpClientService.post(url, request);
    }

    /**
     * Download cibil score
     * @param request req params
     * @returns res from API
     */
    cibilDownload(request: any) {
        const url = environment.endpoint + API.CIBIL_DOWNLOAD;
        return this.httpClientService.post(url, request);
    }

    /**
     * Change cibil status
     * @param request req params
     * @returns res from API
     */
    cibilStatusChange(request: any) {
        const url = environment.endpoint + API.CIBIL_STATUS_SAVE;
        return this.httpClientService.post(url, request);
    }

    /**
     * cibil status get
     * @param request req params
     * @returns res from API
     */
    cibilStatusGet(request: any) {
        const url = environment.endpoint + API.CIBIL_STATUS_GET;
        return this.httpClientService.post(url, request);
    }

    /** Save transaction of NBFC To Doctor
     * @param req req params
     * @returns res from api
     */
    saveNBFCTransaction(req: any) {
        const url = environment.endpoint + API.SAVE_TRANSACTION;
        return this.httpClientService.post(url, req);
    }

    /** Save claim docs of patient. Which will be came from Doctor / Hospital
     * @param req req params
     * @returns res from api
     */
    saveClaimDocs(req: any) {
        let formData: any = new FormData();
        formData.append('loan_id', req.loan_id);

        req.docs.forEach((ele: any) => {
            formData.append('claim_docs', ele);
        });

        const url = environment.endpoint + API.SAVE_CLAIM_DOCUMENTS;
        return this.httpClientService.post(url, formData);
    }

    /** Save needed docs
     * @param req req params
     * @returns res from api
     */
    saveNeededDocs(req: any) {
        let formData: any = new FormData();
        formData.append('loan_id', req.loan_id);
        formData.append('needed_documents_type', JSON.stringify(req.needed_documents_type));

        if (req.needed_documents) {
            req.needed_documents.forEach((ele: any) => {
                if (ele) {
                    formData.append('needed_documents', ele);
                }
            });
        }

        const url = environment.endpoint + API.SAVE_NEEDED_DOCUMENTS;
        return this.httpClientService.post(url, formData);
    }
}
