<div class="documents">
    <div *ngIf="filePreview.length > 0" [class]="'documents' + ' ' + className">
        <ng-container *ngFor="let file of filePreview; let i = index">
            <div *ngIf="file.type.includes('image')" class="show-docs">
                <img [src]="file.file" />
                <i class="fa fa-trash" (click)="removeFile(i)"></i>
            </div>
            <div *ngIf="file.type.includes('pdf')" class="show-docs">
                <i class="fa fa-file-pdf"></i>
                <i class="fa fa-trash" (click)="removeFile(i)"></i>
            </div>
        </ng-container>
    </div>
</div>