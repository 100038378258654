import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { API } from '../_constants';
import { HttpClientService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CastlerService {
  public escrowAcDetails = new BehaviorSubject({ is_transferred: false });

  constructor(
    private httpClientService: HttpClientService
  ) { }

  /**
   * Create Escrow A/C
   * @param request request data
   * @returns res from api
   */
  createEscrowAccount(request: any) {
    const url = environment.endpoint + API.CREATE_ESCROW_AC;
    return this.httpClientService.post(url, request);
  }

  /**
   * Get escrow account details
   * @param request 
   * @returns res from api
   */
  getEscrowAccount(request: any) {
    const url = environment.endpoint + API.GET_ESCROW_AC;
    return this.httpClientService.post(url, request);
  }

  /**
   * Transfer Amount from Patient A/C to
   * Chinmay Finlease and HealthFinit
   * @param request request data
   * @returns res from api
   */
  transferAmountOnChinmayAndHealthFinit(request: any) {
    const url = environment.endpoint + API.TRANSFER_AMOUNT;
    return this.httpClientService.post(url, request);
  }

  // /**
  //  * Create Payee A/C
  //  * @param request request data
  //  * @returns res from api
  //  */
  // createPayeeAccount(request: any) {
  //   const url = environment.endpoint + API.CREATE_ESCROW_AC;
  //   return this.httpClientService.post(url, request);
  // }
}
