import { Component } from '@angular/core';
import { AssetsFile } from '../../_constants';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent {
  files: any = AssetsFile;
}
