export interface Login {
  username: string;
  password: string;
}

export interface SignDoc {
  is_esign_temrs_accept: boolean;
  otp: string;
}

export enum PatientFormStep {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum RoleId {
  ADMIN = 1,
  SUPER_ADMIN = 2,
  HOSPITAL_ADMIN = 3
}

export enum RoleName {
  ADMIN = 'Admin',
  SUPER_ADMIN = 'Super Admin',
  HOSPITAL_ADMIN = 'Hospital Admin'
}

// Super Admin, Admin, Hospital Admin
export const Roles = [
  { id: RoleId.ADMIN, title: RoleName.ADMIN },
  { id: RoleId.SUPER_ADMIN, title: RoleName.SUPER_ADMIN },
  { id: RoleId.HOSPITAL_ADMIN, title: RoleName.HOSPITAL_ADMIN },
];

export const Gender = [
  { id: 1, title: 'Male' },
  { id: 2, title: 'Female' },
  { id: 3, title: 'Other' },
];

export const Relations = [
  { id: 1, title: 'Self' },
  { id: 2, title: 'Mother' },
  { id: 3, title: 'Father' },
  { id: 4, title: 'Brother' },
  { id: 5, title: 'Sister' },
  { id: 6, title: 'Daughter' },
  { id: 7, title: 'Son' },
  { id: 8, title: 'Spouse' },
  { id: 9, title: 'Husbund' },
  { id: 10, title: 'Wife' },
];

export const Income = [
  { id: 1, title: '0 - 25,000' },
  { id: 2, title: '25,000 - 50,000' },
  { id: 3, title: '50,000 - 100,000' },
  { id: 4, title: '100,000 - 300,000' },
  { id: 5, title: 'Above 300,000' },
];

export enum LoanStatusId {
  RECEIVED_BY_HEALTHFINIT = 0,
  APPROVED_BY_HEALTHFINIT = 1,
  REJECTED_BY_HEALTHFINIT = 2,
  ESCALATED = 3,
  VERIFICATION_BY_THIRDPARTY = 4,
  APPROVED_BY_THIRDPARTY = 5,
  SUBJECT_TO = 6,
  REJECTED_BY_THIRDPARTY = 7,
  VIDEO_KYC_IN_PROGRESS = 8,
  VIDEO_KYC_COMPLETED = 9,
  VIDEO_KYC_REJECTED = 10,
  LOAN_AGREEMENT_IN_PROGRESS = 11,
  LOAN_AGREEMENT_COMPLETED = 12,
  LOAN_AGREEMENT_REJECTED = 13,
  DIGITAL_ESCROW_INITIATED = 14,
  DIGITAL_ESCROW_COMPLETED = 15,
  DIGITAL_ESCROW_REJECTED = 16,
  LOAN_DISBURSEMENT = 17,
  LOAN_PROCESS_INITIIATED = 18,
  DOCS_RECEIVED_FROM_DOCTOR = 19,
  DOCS_SEND_TO_PHS = 20,
  QUERY_FROM_PHS = 21,
  PHS_QUERY_RESOLVED = 22,
  CLAIM_APPROVED = 23,
  CLAIM_REJECTED = 24,
  AMOUNT_CREDITED = 25,
  FUND_PENDING = 26,
  FUND_RECOVERED = 27,
}

export enum LoanStatusName {
  RECEIVED_BY_HEALTHFINIT = 'Received By Healthfinit',
  APPROVED_BY_HEALTHFINIT = 'Approved By Healthfinit',
  REJECTED_BY_HEALTHFINIT = 'Rejected By Healthfinit',
  ESCALATED = 'Escalated',
  VERIFICATION_BY_THIRDPARTY = 'Verification By Third Party',
  APPROVED_BY_THIRDPARTY = 'Approved By Third Party',
  SUBJECT_TO = 'Subject To',
  REJECTED_BY_THIRDPARTY = 'Rejected By Third Party',
  VIDEO_KYC_IN_PROGRESS = 'Video KYC In Progress',
  VIDEO_KYC_COMPLETED = 'Video KYC Completed',
  VIDEO_KYC_REJECTED = 'Video KYC Rejected',
  LOAN_AGREEMENT_IN_PROGRESS = 'Loan Agreement In Progress',
  LOAN_AGREEMENT_COMPLETED = 'Loan Agreement Completed',
  LOAN_AGREEMENT_REJECTED = 'Loan Agreement Rejected',
  DIGITAL_ESCROW_INITIATED = 'Digital Escrow Initiated',
  DIGITAL_ESCROW_COMPLETED = 'Digital Escrow Completed',
  DIGITAL_ESCROW_REJECTED = 'Digital Escrow Rejected',
  LOAN_DISBURSEMENT = 'Loan Disbursement',
  LOAN_PROCESS_INITIIATED = 'Loan Proccess Initiated',
  DOCS_RECEIVED_FROM_DOCTOR = 'Final Docs Received From Doctor',
  DOCS_SEND_TO_PHS = 'Final Docs Send To PHS',
  QUERY_FROM_PHS = 'Query came from PHS',
  PHS_QUERY_RESOLVED = 'PHS Query Resolved',
  CLAIM_APPROVED = 'Claim Approved',
  CLAIM_REJECTED = 'Claim Rejected',
  AMOUNT_CREDITED = 'Insurance amount credited in Escrow Account',
  FUND_PENDING = 'Funds Pending',
  FUND_RECOVERED = 'Funds Recovered by HealthFinit',
}

export const LoanStatus = [
  { id: LoanStatusId.RECEIVED_BY_HEALTHFINIT, title: LoanStatusName.RECEIVED_BY_HEALTHFINIT },
  { id: LoanStatusId.APPROVED_BY_HEALTHFINIT, title: LoanStatusName.APPROVED_BY_HEALTHFINIT, is_disabled: true },
  { id: LoanStatusId.REJECTED_BY_HEALTHFINIT, title: LoanStatusName.REJECTED_BY_HEALTHFINIT, is_disabled: true },
  { id: LoanStatusId.ESCALATED, title: LoanStatusName.ESCALATED, is_disabled: true },
  { id: LoanStatusId.VERIFICATION_BY_THIRDPARTY, title: LoanStatusName.VERIFICATION_BY_THIRDPARTY },
  { id: LoanStatusId.APPROVED_BY_THIRDPARTY, title: LoanStatusName.APPROVED_BY_THIRDPARTY },
  { id: LoanStatusId.SUBJECT_TO, title: LoanStatusName.SUBJECT_TO },
  { id: LoanStatusId.REJECTED_BY_THIRDPARTY, title: LoanStatusName.REJECTED_BY_THIRDPARTY },
  { id: LoanStatusId.VIDEO_KYC_IN_PROGRESS, title: LoanStatusName.VIDEO_KYC_IN_PROGRESS },
  { id: LoanStatusId.VIDEO_KYC_COMPLETED, title: LoanStatusName.VIDEO_KYC_COMPLETED },
  { id: LoanStatusId.VIDEO_KYC_REJECTED, title: LoanStatusName.VIDEO_KYC_REJECTED },
  { id: LoanStatusId.LOAN_AGREEMENT_IN_PROGRESS, title: LoanStatusName.LOAN_AGREEMENT_IN_PROGRESS },
  { id: LoanStatusId.LOAN_AGREEMENT_COMPLETED, title: LoanStatusName.LOAN_AGREEMENT_COMPLETED },
  { id: LoanStatusId.LOAN_AGREEMENT_REJECTED, title: LoanStatusName.LOAN_AGREEMENT_REJECTED },
  { id: LoanStatusId.DIGITAL_ESCROW_INITIATED, title: LoanStatusName.DIGITAL_ESCROW_INITIATED },
  { id: LoanStatusId.DIGITAL_ESCROW_COMPLETED, title: LoanStatusName.DIGITAL_ESCROW_COMPLETED },
  { id: LoanStatusId.DIGITAL_ESCROW_REJECTED, title: LoanStatusName.DIGITAL_ESCROW_REJECTED },
  { id: LoanStatusId.LOAN_DISBURSEMENT, title: LoanStatusName.LOAN_DISBURSEMENT },
  // DR and NBFC Flow
  { id: 'NBFC & DR Flow', title: 'NBFC & DR Flow', is_disabled: true },
  { id: LoanStatusId.LOAN_PROCESS_INITIIATED, title: LoanStatusName.LOAN_PROCESS_INITIIATED },
  { id: LoanStatusId.DOCS_RECEIVED_FROM_DOCTOR, title: LoanStatusName.DOCS_RECEIVED_FROM_DOCTOR },
  { id: LoanStatusId.DOCS_SEND_TO_PHS, title: LoanStatusName.DOCS_SEND_TO_PHS },
  { id: LoanStatusId.QUERY_FROM_PHS, title: LoanStatusName.QUERY_FROM_PHS },
  { id: LoanStatusId.PHS_QUERY_RESOLVED, title: LoanStatusName.PHS_QUERY_RESOLVED },
  { id: LoanStatusId.CLAIM_APPROVED, title: LoanStatusName.CLAIM_APPROVED },
  { id: LoanStatusId.CLAIM_REJECTED, title: LoanStatusName.CLAIM_REJECTED },
  { id: LoanStatusId.AMOUNT_CREDITED, title: LoanStatusName.AMOUNT_CREDITED },
  { id: LoanStatusId.FUND_PENDING, title: LoanStatusName.FUND_PENDING },
  { id: LoanStatusId.FUND_RECOVERED, title: LoanStatusName.FUND_RECOVERED }
];

export enum LoanDetailsStep {
  PERSONAL_INFO = 'PERSONAL_INFO',
  PATIENT_INFO = 'PATIENT_INFO',
  AADHAR_PAN_INFO = 'AADHAR_PAN_INFO',
  INSURANCE_INFO = 'INSURANCE_INFO',
  TRANSACTION_INFO = 'TRANSACTION_INFO',
  CASTLER_PROCESS = 'CASTLER_PROCESS',
  ALL_DOCUMENTS = 'ALL_DOCUMENTS',
  TREATMENT_INFO = 'TREATMENT_INFO',
};

export interface PolicyHolderForm {
  user_id: number;
  name: string;
  gender: boolean;
  email: string;
  date_of_birth: Date;
  aadhaar_number: any;
  pan_number: any;
  monthly_income: number;
  address: string;
  city: string;
  state: string;
  pin_code: string;
  is_address_different: boolean;
  current_address: string;
  current_city: string;
  current_state: string;
  current_pin_code: string;
  phone_no: string;
  insured_value: string;
};

export interface PatientForm {
  loan_id: number;
  patient_name: string;
  patient_date_of_birth: Date;
  relation: number;
  name_of_clinic: string;
};

export interface InsuranceForm {
  loan_id: number;
  insured_value: string;
}

export interface TreatmentForm {
  loan_id: number;
  hospital_id: number;
  treatment_type: number,
  treatment: number,
  estimated_cost: number,
  is_na: boolean,
  is_htn: boolean,
  is_dm: boolean,
  others_disease: string,
}

// EXPORT PATIENT DETAILS IN EXCEL
export enum ExcelHeader {
  POLICY_HOLDER_NAME = 'Policy Holder Name',
  POLICY_HOLDER_AADHAR_NO = 'Policy Holder Aadhar No',
  POLICY_HOLDER_PAN_NO = 'Policy Holder Pan No',
  POLICY_HOLDER_ADDRESS = 'Policy Holder Address',
  POLICY_HOLDER_CURRENT_ADDRESS = 'Policy Holder Current Address',
  POLICY_HOLDER_DOB = 'Policy Holder DOB',
  POLICY_HOLDER_GENDER = 'Policy Holder Gender',
  POLICY_HOLDER_MOBILE_NO = 'Policy Holder Mobile No',
  PATIENT_NAME = 'Patient Name',
  PATIENT_DOB = 'Patient DOB',
  TREATMENT = 'Treatment',
  ESTIMATED_COST = 'Estimated Cost',
  INSURED_VALUE = 'Insured Value',
}

export const AdminTableCols = [
  { title: 'Name', key: 'name' },
  { title: 'Email', key: 'email' },
  { title: 'Role', key: 'role' },
];

export const PatientTableCols = [
  { title: 'Name', key: 'name' },
  { title: 'Mobile No', key: 'phone_no' },
  { title: 'Aadhar No', key: 'aadhaar_number' },
];

export const CibilStatus = [
  { title: 'Yes', id: 1 },
  { title: 'No', id: 2 }
];