<div class="wrapper">
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
            <li class="nav-item menu-bar">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
        </ul>

        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>
            <li class="nav-item dropdown user user-menu open">
                <a href="#" class="nav-link dropdown-toggle user-img" data-toggle="dropdown" aria-expanded="true">
                    <img [src]="files.PATIENT" class="user-image" alt="User Image" />
                </a>
                <ul class="dropdown-menu user-profile">
                    <li class="user-header">
                        <img [src]="files.PATIENT" class="img-circle" alt="User Image">
                        <p> {{userDetails?.name}} </p>
                        <small>{{userDetails?.phone_no}}</small>
                    </li>

                    <li class="user-footer">
                        <div class="d-flex justify-content-between">
                            <!-- <button class="btn btn-primary"></button> -->
                            <button class="btn btn-default" (click)="logout()">Logout</button>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>

    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" class="brand-link">
            <img [src]="files.LOGO" alt="Logo" />
        </a>

        <div class="sidebar">
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
                    <li *ngFor="let menulist of sideBarMenu" class="nav-item has-treeview">
                        <a *ngIf="!menulist?.subMenu" href="#" [routerLink]="[menulist.route]"
                            [routerLinkActive]="'is-active-link'" class="d-flex align-items-center nav-link"
                            [attr.data-widget]="screenWidth < 992 ? 'pushmenu' : ''">
                            <i class="mr-3 nav-icon {{menulist.icon}}"></i>
                            <p> {{menulist.title}} </p>
                        </a>
                        <a *ngIf="menulist?.subMenu" href="#" class="nav-link">
                            <i class="mr-3 nav-icon {{menulist.icon}}"></i>
                            <p> {{menulist.title}} <i class="right fas fa-angle-left"></i> </p>
                        </a>
                        <ul *ngIf="menulist?.subMenu" class="nav nav-treeview">
                            <li *ngFor="let submenu of menulist?.subMenu" class="nav-item">
                                <a [routerLink]="[submenu.route]" class="d-flex align-items-center nav-link"
                                    [routerLinkActive]="'is-active-link'"
                                    [attr.data-widget]="screenWidth < 992 ? 'pushmenu' : ''">
                                    &nbsp; &nbsp;<i class="mr-3 nav-icon {{submenu.icon}}"></i>
                                    <p>{{submenu.title}}</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>

    <div class="content-wrapper">
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <footer class="main-footer">
        <strong>Copyright &copy; 2022 <a [href]="healthFinit" target="_blank">HEALTHFINIT</a>.</strong>
        All rights reserved.
    </footer>
    <aside class="control-sidebar control-sidebar-dark"></aside>
</div>