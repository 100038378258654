import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatService, CommonService } from './_services';
import { RouteNames, StorageKey } from './_constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loadingText: string = 'Loading...';

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private chatService: ChatService
  ) {
    this.chatService.connectSocket();
    this.setLoanUrl();
    this.spinner.show();
  }

  ngOnInit(): void {
    this.showHideSpinner();
  }

  /**
   * Set loan application url
   */
  setLoanUrl() {
    if (!this.commonService.getLocalStorage(StorageKey.FRONT_TOKEN) &&
      location.href.includes(RouteNames.MY_LOAN_APPLICATIONS)
    ) {
      const appLink = `${location.href}`;
      this.commonService.setLocalStorage(StorageKey.LOAN_APPLICATION_URL, appLink);
    }
  }

  /**
   * On page route page scroll top
   */
  onActive() {
    this.router.events.subscribe(() => {
      window.scrollTo({
        top: 1,
        left: 0,
        behavior: 'smooth'
      });
    });
  }

  /**
   * Show / Hide spinner
   */
  showHideSpinner() {
    this.commonService.isHideSpinner.subscribe((res: any) => {
      if (res && res?.is_hide_spinner) {
        this.spinner.hide();
      }
    });
  }
}
