import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API } from '../_constants/api';
import { HttpClientService } from './index';

@Injectable({
    providedIn: 'root'
})
export class PatientService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Send otp for aadhar verification
     * @param request req params
     * @returns res from API
     */
    sendAadharOTP(request: any) {
        const url = environment.endpoint + API.SEND_AADHAR_OTP;
        return this.httpClientService.post(url, request);
    }

    /**
     * To verify aadhar otp and save info
     * @param request request data
     * @returns res form the api
     */
    verifyAddharOTPAndSaveInfo(request: any) {
        const url = environment.endpoint + API.VERIFY_AADHAR_OTP;
        return this.httpClientService.post(url, request);
    }

    /**
     * Verify pan number
     * @param request req params
     * @returns res from API
     */
    verifyPanNo(request: any) {
        const url = environment.endpoint + API.PAN_VERIFICATION;
        return this.httpClientService.post(url, request);
    }

    /**
     * To save aadhar and pan info
     * @param request request data
     * @returns res form the api
     */
    saveAddharAndPanInfo(request: any) {
        const url = environment.endpoint + API.SAVE_ADDHAR_PAN_INFO;
        return this.httpClientService.post(url, request);
    }

    /**
     * Save personal info
     * @param request request data
     * @returns res from api
     */
    savePersonalInfo(request: any) {
        const url = environment.endpoint + API.SAVE_PERSONAL_INFO;
        return this.httpClientService.post(url, request);
    }

    /**
     * Save personal info for cibil
     * @param request request data
     * @returns res from api
     */
    saveDataForCibil(request: any) {
        const url = environment.endpoint + API.SAVE_PERSONAL_INFO_FOR_CIBIL;
        return this.httpClientService.post(url, request);
    }

    /**
     * If document is not valid
     * @param request request data
     * @returns res from api
     */
    documentNotValid(request: any) {
        const url = environment.endpoint + API.WP_JOURNEY_DOC_NOT_VALID;
        return this.httpClientService.post(url, request);
    }

    /**
     * Verify CIBIL score and send OTP
     * @param request req params
     * @returns res from API
     */
    verifyCibilAndSendOTP(request: any) {
        const url = environment.endpoint + API.CIBIL_VERIFY;
        return this.httpClientService.post(url, request);
    }

    /**
     * Verify CIBIL OTP
     * @param request req params
     * @returns res from API
     */
    verifyCibilOTP(request: any) {
        const url = environment.endpoint + API.CIBIL_OTP_VERIFY;
        return this.httpClientService.post(url, request);
    }

    savePatientInfo(request: any) {
        const formData: FormData = new FormData();
        formData.append('loan_id', request.loan_id);
        formData.append('is_patient_itself', request.is_patient_itself);
        formData.append('patient_name', request.patient_name);
        formData.append('patient_date_of_birth', request.patient_date_of_birth);
        formData.append('relation', request.relation);


        if (request.is_super_admin) {
            formData.append('is_super_admin', request.is_super_admin);
        } else {
            if (!request.is_patient_itself) {
                formData.append('patient_pan_image', request.patient_pan_image);
                formData.append('patient_aadhaar_front', request.patient_aadhaar_front);
                formData.append('patient_aadhaar_back', request.patient_aadhaar_back);
            }
        }
        const url = environment.endpoint + API.SAVE_PATIENT_DETAILS;
        return this.httpClientService.post(url, formData);
    }

    /**
     * Save treatment details
     * @param request req params
     * @returns res form API
     */
    saveTreatmentDetails(request: any) {
        const formData: FormData = new FormData();
        formData.append('hospital_id', request.hospital_id);
        formData.append('is_na', request.is_na);
        formData.append('is_htn', request.is_htn);
        formData.append('is_dm', request.is_dm);
        formData.append('others_disease', request.others_disease);
        formData.append('treatment', request.treatment);
        formData.append('treatment_type', request.treatment_type);
        formData.append('estimated_cost', request.estimated_cost);

        if (request.doctor_prescription) {
            request.doctor_prescription.forEach((ele: any) => {
                formData.append('doctor_prescription', ele);
            });
        }

        if (request.diagnostic_reports) {
            request.diagnostic_reports.forEach((ele: any) => {
                formData.append('diagnostic_reports', ele);
            });
        }

        if (request.medical_docs) {
            request.medical_docs.forEach((ele: any) => {
                formData.append('medical_docs', ele);
            });
        }

        if (request.loan_id) {
            formData.append('loan_id', request.loan_id);
        }

        if (request.is_super_admin) {
            formData.append('is_super_admin', request.is_super_admin);
        }
        const url = environment.endpoint + API.SAVE_TREATMENT_DETAILS;
        return this.httpClientService.post(url, formData);
    }

    /**
     * Save insurance details
     * @param request req params
     * @returns res form API
     */
    saveInsurance(request: any) {
        const formData: FormData = new FormData();
        if (request.loan_id) {
            formData.append('loan_id', request.loan_id);
        }
        formData.append('insured_value', request.insured_value);

        if (request?.is_super_admin) {
            formData.append('is_super_admin', request.is_super_admin);
        } else {
            formData.append('apply_for', request.apply_for);
            request.document.forEach((ele: any) => {
                formData.append('document', ele);
            });
        }

        const url = environment.endpoint + API.SAVE_INSURANCE_DETAILS;
        return this.httpClientService.post(url, formData);
    }

    /**
     * Send otp for login
     * @param request req params
     * @returns res from API
     */
    sendLoginOTP(request: any) {
        const url = environment.endpoint + API.SEND_LOGIN_OTP;
        return this.httpClientService.post(url, request);
    }

    /**
     * Login Patient
     * @param request req params
     * @returns res from API
     */
    loginPatient(request: any) {
        const url = environment.endpoint + API.LOGIN;
        return this.httpClientService.post(url, request);
    }

    /**
     * Send otp for aadhar verification
     * @param request req params
     * @returns res from API
     */
    sendAadharNoOTP(request: any) {
        const url = environment.endpoint + API.SEND_AADHAR_NO_OTP;
        return this.httpClientService.post(url, request);
    }

    // Hospital Admin Signup

    /**
     * Save PatientTreatmentInsurance details
     * @param request req params
     * @returns res form API
     */
    savePatientTreatmentInsurance(request: any, fileObjs: any) {
        const formData: FormData = new FormData();
        formData.append('hospital_id', request.hospital_id);
        formData.append('is_patient_itself', request.is_patient_itself);
        formData.append('patient_name', request.patient_name);
        formData.append('patient_date_of_birth', request.patient_date_of_birth);
        formData.append('relation', request.relation);
        formData.append('treatment', request.treatment);
        formData.append('estimated_cost', request.estimated_cost);
        formData.append('treatment_type', request.treatment_type);
        formData.append('is_na', request.is_na);
        formData.append('is_htn', request.is_htn);
        formData.append('is_dm', request.is_dm);
        formData.append('others_disease', request.others_disease);
        formData.append('insured_value', request.insured_value);
        formData.append('apply_for', request.apply_for);


        // Append each file to the formData object
        if (fileObjs && fileObjs.document && fileObjs.document.length > 0) {
            for (let i = 0; i < fileObjs.document.length; i++) {
                formData.append('document', fileObjs.document[i]);
            }
        }

        if (fileObjs && fileObjs.doctor_prescription && fileObjs.doctor_prescription.length > 0) {
            for (let i = 0; i < fileObjs.doctor_prescription.length; i++) {
                formData.append('doctor_prescription', fileObjs.doctor_prescription[i]);
            }
        }

        if (fileObjs && fileObjs.diagnostic_reports && fileObjs.diagnostic_reports.length > 0) {
            for (let i = 0; i < fileObjs.diagnostic_reports.length; i++) {
                formData.append('diagnostic_reports', fileObjs.diagnostic_reports[i]);
            }
        }

        if (fileObjs && fileObjs.medical_docs && fileObjs.medical_docs.length > 0) {
            for (let i = 0; i < fileObjs.medical_docs.length; i++) {
                formData.append('medical_docs', fileObjs.medical_docs[i]);
            }
        }

        if (request.loan_id) {
            formData.append('loan_id', request.loan_id);
        }

        if (!request.is_patient_itself) {
            formData.append('patient_pan_image', request.patient_pan_image);
            formData.append('patient_aadhaar_front', request.patient_aadhaar_front);
            formData.append('patient_aadhaar_back', request.patient_aadhaar_back);
        }

        const url = environment.endpoint + API.SAVE_PATIENT_TREATMENT_INSUREANCE;
        return this.httpClientService.post(url, formData);
    }


    /**
    * To save aadhar and pan info
    * @param request request data
    * @returns res form the api
    */
    saveAddharAndPan(request: any, fileObj: any) {
        const formData: FormData = new FormData();
        formData.append('aadhaar_number', request.aadhaar_number);
        formData.append('pan_number', request.pan_number);

        if (fileObj.length === 3 && fileObj) {
            formData.append('aadhaar_front', fileObj[0]);
            formData.append('aadhaar_back', fileObj[1]);
            formData.append('pan_image', fileObj[2]);
        }

        const url = environment.endpoint + API.SAVE_ADDHAR_PAN_INFO;
        return this.httpClientService.post(url, request);
    }


    /**
     * To save aadhar and pan info
     * @param request request data
     * @returns res form the api
     */
    saveAddharAndPanInfoDoc(request: any, fileObj: any) {
        const formData: FormData = new FormData();
        formData.append('phone_no', request.phone_no);

        if (fileObj.length === 3 && fileObj) {
            formData.append('aadhaar_front', fileObj[0]);
            formData.append('aadhaar_back', fileObj[1]);
            formData.append('pan_image', fileObj[2]);
        }

        const url = environment.endpoint + API.SAVE_ADDHAR_PAN;
        return this.httpClientService.post(url, formData);
    }
}