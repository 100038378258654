import { Component, HostListener, OnInit } from '@angular/core';
import { AssetsFile, HealthFinit, PATIENT, RouteNames, StorageKey } from '../_constants';
import { CommonService } from '../_services';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  sideBarMenu: any = PATIENT;
  files: any = AssetsFile;
  userDetails: any = this.commonService.getLocalStorage(StorageKey.USER_INFO);
  healthFinit: string = HealthFinit;
  screenWidth = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.onResize();
  }

  /**
   * User logged out
   */
  logout() {
    localStorage.removeItem(StorageKey.FRONT_TOKEN);
    localStorage.removeItem(StorageKey.USER_INFO);
    localStorage.removeItem(StorageKey.LOAN_APPLICATION_URL);
    location.href = RouteNames.LOGIN;
  }
}
