import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as XLSX from 'xlsx';

@Injectable()
export class ExportCsvService {

    constructor(
        private sanitizer: DomSanitizer,
    ) { }

    /**
     * Export data in excel file
     * @param json array
     * @param excelFileName file name
     */
    exportAsExcelFile(json: any[], excelFileName: string): void {
        const EXCEL_TYPE = '.csv';
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

        this.downloadFile(excelBuffer, EXCEL_TYPE, excelFileName);
    }

    /**
     * Download excel file
     * @param excelBuffer file
     * @param EXCEL_TYPE file extension
     * @param excelFileName file name
     */
    downloadFile(excelBuffer: any, EXCEL_TYPE: string, excelFileName: string) {
        const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
        const params: any = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
        let anchorTag: any = document.createElement('a');
        anchorTag.href = params.changingThisBreaksApplicationSecurity;
        anchorTag.download = excelFileName + EXCEL_TYPE;
        anchorTag.click();
    }
}