export interface NBFCToDr {
    loan_id: any;
    payment_terms_id: any;
    amount: number;
    transaction_date: Date | null;
}

export const OwnershipType = [
    { id: 1, title: 'Proprietary' },
    { id: 2, title: 'Partnership' },
    { id: 3, title: 'LLP' },
    { id: 4, title: 'Private Limited' },
    { id: 5, title: 'Public Limited' },
    { id: 6, title: 'Charitable Trust' },
];

export const HospitalTableCols = [
    { title: 'Hospital Name', key: 'name_of_clinic' },
    { title: 'Address', key: 'address_hospital' },
    { title: 'MCI', key: 'mci' },
];

export const HospitalInquiryTableCols = [
    { title: 'Hospital Name', key: 'hospital_name' },
    { title: 'Doctor Name', key: 'doctor_name' },
    { title: 'Email', key: 'email' },
    { title: 'Mobile No', key: 'mobile_no' },
    { title: 'Address', key: 'hospital_address' },
];

export const HospitalMasterTblCols = [
    { title: 'Hospital Name', key: 'hospital_name' },
    { title: 'Hospital Number', key: 'hospital_no' },
];