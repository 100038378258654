import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatService, CommonService } from '../../_services';

@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.scss']
})
export class VideoChatComponent implements OnInit {
  homeStream: any;
  kycCallUrl: string = '';
  toolTip: string = '';
  isAcceptedCall: boolean = false;
  recordVideoElement: HTMLVideoElement | any;
  mediaRecorder: any;
  recordedBlobs: Blob[] | any;
  downloadUrl: any;
  isConnectedCall: boolean = false;

  constructor(
    public matDialogRef: MatDialogRef<VideoChatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public chatService: ChatService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    if (this.data.flag) {
      this.onCallAction(1);
    }
    this.chatService.getActionOnVideoKycCall().subscribe((res: any) => {
      if (res && res.receiver_id === this.data.id || res.receiver_id === this.data.admin_uid) {
        this.onCallAction(res.isAcceptCall);
        if (res.isAcceptCall === 1 && !res.flag) {
          this.startRecording();
          this.isConnectedCall = true;
        }
      }
    });

    setTimeout(() => {
      this.chatService.makeVideoKycCall({
        userDetails: this.data,
        receiver_id: this.data.id,
        peerId: this.chatService.pId
      });
    }, 3000);
  }

  connectCall(peerId: any) {
    setTimeout(() => {
      this.chatService.call(peerId);
    }, 3000);
  }

  acceptOrRejectCall(value: number) {
    let receiver_id: any = this.data.admin_uid;
    if (this.data.flag) {
      receiver_id = this.data.id;
    }
    this.chatService.actionOnVideoKycCall({
      ...this.data,
      receiver_id,
      isAcceptCall: value
    });

    setTimeout(() => {
      this.onCallAction(value);
    }, 500);
  }

  onCallAction(value: any) {
    if (value === 1) {
      this.isAcceptedCall = true;
      this.chatService.setupCall();
      this.chatService.getUserMedia().subscribe((stream: any) => {
        this.homeStream = stream;
        if (!this.data.flag) {
          this.connectCall(this.data.peerId);
        }
      });
    } else {
      if (this.data.flag) {
        if (this.isConnectedCall) {
          this.stopRecording();
        } else {
          location.reload();
          // this.matDialogRef.close();
        }
      } else {
        location.reload();
        // this.matDialogRef.close();
      }
    }

    if (!this.data.flag) {
      this.commonService.playRingtone(false);
    }
  }

  startRecording() {
    this.recordedBlobs = [];
    let options: any = { mimeType: 'video/webm' };

    try {
      navigator.mediaDevices.getDisplayMedia({ video: true, audio: { echoCancellation: false, noiseSuppression: true } })
        .then((screenStream) => {
          navigator.mediaDevices.getUserMedia({ video: true, audio: { echoCancellation: false, noiseSuppression: true } })
            .then((micStream) => {
              const audioContext = new AudioContext();
              const screenAudioSource = audioContext.createMediaStreamSource(screenStream);
              const micAudioSource = audioContext.createMediaStreamSource(micStream);

              const destination = audioContext.createMediaStreamDestination();
              screenAudioSource.connect(destination);
              micAudioSource.connect(destination);

              const mixedStream = destination.stream;

              const finalStream = new MediaStream();
              // Add video track from screen stream
              screenStream.getVideoTracks().forEach((track) => {
                finalStream.addTrack(track);
              });
              // Add audio track from mixed stream
              mixedStream.getAudioTracks().forEach((track) => {
                finalStream.addTrack(track);
              });

              this.mediaRecorder = new MediaRecorder(finalStream, options);
              this.mediaRecorder.start(); // collect 100ms of data
              this.onDataAvailableEvent();
              this.onStopRecordingEvent();
            })
            .catch((micError) => {
              console.log(micError);
            });
        })
        .catch((screenError) => {
          console.log(screenError);
        });
    } catch (err) {
      console.log(err);
    }
  }

  stopRecording() {
    this.mediaRecorder?.stop();
    location.reload();
  }

  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  onStopRecordingEvent() {
    try {
      this.mediaRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.recordedBlobs, {
          type: 'video/webm'
        });
        this.downloadUrl = window.URL.createObjectURL(videoBuffer);
        this.commonService.downloadFile(this.downloadUrl);
        location.reload();
        this.matDialogRef.close();
      };
    } catch (error) {
      console.log(error);
    }
  }
}