import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageSize, Pagination } from '../../_constants';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() isSHowPagination: boolean = false;
  @Input() pageConfig: any = JSON.parse(JSON.stringify(Pagination));
  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();

  defaultPageSize: any = PageSize;

  /**
   * On page change
   * @param event page number
   */
  onPageChange(event: any) {
    this.pageConfig.currentPage = event.pageIndex + 1;
    this.pageConfig.pageSize = event.pageSize;
    this.onPageChanged.emit(this.pageConfig);
  }
}
