import { Pipe, PipeTransform } from '@angular/core';
import { LoanStatus } from '../_interface';

@Pipe({
    name: 'LoanStatus'
})
export class LoanStatusPipe implements PipeTransform {
    transform(statusId: number): any {
        return LoanStatus.filter((item: any) => Number(item.id) === Number(statusId))[0]?.title;
    }
}
