import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
    AdminPatientChatComponent, ConfirmDialogComponent, CommonTableComponent,
    GenerateQrCodeComponent, VideoFrameComponent, VideoChatComponent, CommonQrComponent,
    AuthHeaderComponent, ViewDocumentsComponent, ViewDownloadFileComponent, DigitalSignComponent,
    ViewPdfComponent, ShowAmountComponent, ViewBase64FilesComponent, PageHeaderComponent, PaginationComponent
} from './index';
import { environment } from '../../environments/environment';
import { MaterialModule } from '../material.module';
import { PipeModule } from '../_pipe/pipe.module';

const config: SocketIoConfig = { url: environment.endpoint, options: {} };

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        CommonTableComponent,
        CommonQrComponent,
        GenerateQrCodeComponent,
        AdminPatientChatComponent,
        VideoChatComponent,
        VideoFrameComponent,
        AuthHeaderComponent,
        PageHeaderComponent,
        ViewDocumentsComponent,
        ViewDownloadFileComponent,
        DigitalSignComponent,
        ViewPdfComponent,
        ShowAmountComponent,
        ViewBase64FilesComponent,
        PaginationComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        QRCodeModule,
        PipeModule,
        PdfViewerModule,
        SocketIoModule.forRoot(config)
    ],
    entryComponents: [
        ConfirmDialogComponent,
        VideoChatComponent
    ],
    exports: [
        CommonTableComponent,
        GenerateQrCodeComponent,
        AdminPatientChatComponent,
        VideoFrameComponent,
        AuthHeaderComponent,
        PageHeaderComponent,
        ViewDocumentsComponent,
        ViewDownloadFileComponent,
        DigitalSignComponent,
        ViewPdfComponent,
        ShowAmountComponent,
        ViewBase64FilesComponent,
        PaginationComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
