import { Pipe, PipeTransform } from '@angular/core';
import { Relations } from '../_interface';

@Pipe({
    name: 'Relations'
})
export class RelationPipe implements PipeTransform {
    transform(relationId: number): any {
        return Relations.filter((item: any) => Number(item.id) === Number(relationId))[0]?.title;
    }
}
