import { NgModule } from '@angular/core';
import { GenderPipe } from './gender.pipe';
import { RelationPipe } from './relation.pipe';
import { IncomePipe } from './income.pipe';
import { MessagePipe } from './message.pipe';
import { AdminRole } from './admin-role.pipe';
import { LoanStatusPipe } from './loan-status.pipe';


@NgModule({
    declarations: [
        GenderPipe,
        IncomePipe,
        RelationPipe,
        MessagePipe,
        AdminRole,
        LoanStatusPipe
    ],
    exports: [
        GenderPipe,
        IncomePipe,
        RelationPipe,
        MessagePipe,
        AdminRole,
        LoanStatusPipe
    ],
    providers: [GenderPipe]
})
export class PipeModule { }
