import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-documents',
  templateUrl: './view-documents.component.html',
  styleUrls: ['./view-documents.component.scss']
})
export class ViewDocumentsComponent {
  @Input() files: any = '';
  @Input() pdfConfig: any;
  @Input() fileSize: any = {
    width: 150, height: 100, fontSize: 100
  };
}
