import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import SignaturePad from 'signature_pad';
import { CommonService, LoanService, PatientService } from '../../_services';
import { LoanStatusId, SignDoc } from '../../_interface';

@Component({
  selector: 'app-digital-sign',
  templateUrl: './digital-sign.component.html',
  styleUrls: ['./digital-sign.component.scss']
})
export class DigitalSignComponent {
  isShowOTP: boolean = false;
  customInstance: any;
  signDocs: any = {} as SignDoc;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;

  constructor(
    private loanService: LoanService,
    private commonService: CommonService,
    private patientService: PatientService,
    public matDialogRef: MatDialogRef<DigitalSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  /**
   * Send OTP on check checkbox of terms and condition
   */
  onTermsClick() {
    if (this.signDocs.is_esign_temrs_accept) {
      const req: any = {
        aadhaar_number: this.data.userDetails.personal_info[0].aadhaar_number
      };
      this.patientService.sendAadharNoOTP(req).subscribe({
        next: (res: any) => {
          this.isShowOTP = res.success;
          this.commonService.emitValueForSpinner();
          this.commonService.toaster(res);
        }
      });
    }
  }

  /**
   * Verify OTP for accept terms and condition
   * Generate Signed docs on OTP verified sucessfully
   */
  verifyOtpForTerms() {
    if (this.signaturePad.isEmpty()) {
      const res = { message: 'Please sign the agreement', success: false };
      this.commonService.toaster(res);
      return;
    }
    let req: any = {
      aadhaar_number: this.data.userDetails.personal_info[0].aadhaar_number,
      otp: this.signDocs.otp,
      id: this.data.userDetails.id,
    };

    switch (this.data.userDetails.loan_current_status) {
      case LoanStatusId.LOAN_AGREEMENT_IN_PROGRESS:
        req.is_esign_temrs_accept = this.signDocs.is_esign_temrs_accept;
        break;
      case LoanStatusId.DIGITAL_ESCROW_INITIATED:
        req.is_read_term_e_agrmt = this.signDocs.is_esign_temrs_accept;
        break;
    }

    this.loanService.verifyOTPForSignDocs(req).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.downloadPDF();
          this.isShowOTP = false;
        }
        this.commonService.emitValueForSpinner();
        this.commonService.toaster(res);
      }
    });
  }

  startDrawing(event: Event) { }

  moved(event: Event) { }

  clearPad() {
    this.signaturePad.clear();
  }

  async downloadPDF() {
    const base64Data = this.signaturePad.toDataURL("image/png");
    this.signatureImg = base64Data;

    const signatureText = `Signed By ${this.data.userDetails.personal_info[0].name}`;
    const fontSize = 14; // Set the desired font size
    const margin = 40;

    // Fetch the original PDF
    const pdfResponse = await fetch(this.data.url);
    const pdfData = await pdfResponse.arrayBuffer();

    try {
      // Load the PDF document
      const pdfDoc = await PDFDocument.load(pdfData);

      // Get the total number of pages in the original PDF
      const totalPages = pdfDoc.getPageCount();

      // Get the last page of the PDF
      const lastPage = pdfDoc.getPage(totalPages - 1);

      // Get the page size of the last page
      const pageSize = lastPage.getSize();
      const pageWidth = pageSize.width;
      const pageHeight = pageSize.height;

      // Add the signature text to the last page
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const textWidth = helveticaFont.widthOfTextAtSize(signatureText, fontSize);
      const textX = pageWidth - textWidth - margin;
      const textY = margin;
      lastPage.drawText(signatureText, { x: textX, y: textY, size: fontSize, font: helveticaFont, color: rgb(0, 0, 0) });

      // Add the signature image to the last page
      const signatureImgImage = await pdfDoc.embedPng(this.signatureImg);
      const imgWidth = 100; // Set the desired width of the signature image
      const imgHeight = (imgWidth * signatureImgImage.height) / signatureImgImage.width;
      lastPage.drawImage(signatureImgImage, {
        x: textX, // Align the image with the signature text
        y: textY + 20, // Move the image down a bit from the text
        width: imgWidth,
        height: imgHeight,
      });

      // Save the modified PDF as a blob
      const modifiedPdfData = await pdfDoc.save();

      // Create a download link and trigger the download
      const modifiedPdfBlob = new Blob([modifiedPdfData], { type: 'application/pdf' });
      this.closeModal(modifiedPdfBlob);
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(modifiedPdfBlob);
      // link.download = 'Sign_file.pdf'; // Specify the desired name for the downloaded file
      // link.click();
    } catch (error) {
      console.error('Error loading or modifying PDF:', error);
    }
  }

  /**
   * Close modal
   * Send signed docs on chat to admin on the close modal
   * @param file signed docs
   */
  closeModal(file: any = '') {
    this.matDialogRef.close(file);
  }
}
