import {
  Component, EventEmitter, Input, OnInit,
  Output, ViewChild, ViewEncapsulation, OnDestroy
} from '@angular/core';
import { Subject, distinctUntilChanged, debounceTime } from 'rxjs';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '../../material';
import { ConfirmDialogComponent } from '..';
import {
  Actions, DeleteMsg, FAICONS, Pagination, NoDataFound
} from '../../_constants';
import { CommonService } from '../../_services';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonTableComponent implements OnInit, OnDestroy {
  @Input() tableColumns: any = [];
  @Input() btnConfig: any = {}

  @Output() onActionButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild(MatSort) sort: MatSort | any;

  debounceTime = new Subject<string>();
  actions: any = Actions;
  faIcon: any = FAICONS;
  pageConfig: any = JSON.parse(JSON.stringify(Pagination));

  dataSource: MatTableDataSource<any> | any;
  displayedColumns: string[] = [];

  searchText: string = '';
  noDataFound: string = '';
  isCheckAll: boolean = false;
  selectedRecords: any = [];
  isSHowPagination = false;
  tableData: any = [];
  defaultBtnConfig: any = {
    isShowAdd: true,
    isShowAction: true,
    isShowCheckBox: true,
    isShowExport: false,
  };

  constructor(
    private matDialog: MatDialog,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.setTableConfig();
    this.getSetTableData();
    this.onSearchItem();
  }

  /**
   * Set table configuration
   */
  setTableConfig() {
    this.btnConfig = {
      ...this.defaultBtnConfig,
      ...this.btnConfig
    };

    if (this.btnConfig.isShowCheckBox) {
      this.displayedColumns = ['checkbox'];
    }

    this.tableColumns.forEach((items: any) => {
      this.displayedColumns.push(items.key);
    });

    if (this.btnConfig.isShowAction) {
      this.displayedColumns[this.displayedColumns.length] = 'action';
    }
  }

  /**
   * Get and Set table data
   */
  getSetTableData() {
    this.commonService.setTableData.subscribe((res: any) => {
      this.noDataFound = '';
      this.isSHowPagination = false;
      this.tableData = [];
      this.selectRecords();
      this.dataSource = new MatTableDataSource(this.tableData);
      if (res && res.items) {
        if (res.items.data?.length > 0) {
          const resData: any = {
            data: res.items.data,
            pageInfo: res.items.pageInfo
          };
          this.tableData = resData.data;
          this.pageConfig = {
            currentPage: resData.pageInfo.currentPage,
            pageSize: resData.pageInfo.pageSize,
            totalItems: resData.pageInfo.totalItems
          };
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.isSHowPagination = true;
            this.dataSource.sort = this.sort;
          });
        } else {
          this.noDataFound = NoDataFound;
        }
      } else if (!res.items) {
        this.noDataFound = NoDataFound;
      }
    });
  }

  /**
   * Select records
   * @param event change event object
   */
  selectRecords(event?: any) {
    if (event) {
      this.commonService.multiSelect(event, this.tableData);
    } else {
      this.commonService.isCheckAll = false;
      this.commonService.selectedRecords = [];
    }
    this.isCheckAll = this.commonService.isCheckAll;
    this.selectedRecords = this.commonService.selectedRecords;
  }

  /**
   * Delete record
   * @param item record id
   */
  dialogConfirmation(item: any) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, DeleteMsg);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onActionClick(Actions.DELETE, item);
      }
    });
  }

  /**
   * On action click to perform some action
   * @param flag Add, Update, Delete Or View
   * @param item Record details
   */
  onActionClick(flag: any, item: any = '') {
    const data: any = { flag, item };
    if (flag === Actions.DELETE) {
      data.selectedRecords = this.selectedRecords.length > 0 ? this.selectedRecords : [item.id];
    }
    this.onActionButtonClick.emit(data);
  }

  /**
   * On search
   */
  onSearchItem() {
    this.debounceTime.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
      this.onSearch.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.noDataFound = '';
    this.tableData = [];
    this.dataSource = new MatTableDataSource([]);
    this.commonService.setTableData.next({});
  }
}
