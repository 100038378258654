import { Directive, Input, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CommonService } from '../_services';

interface CredentialRequestOptions {
  otp: any;
  signal: any;
}

@Directive()
export abstract class AbstractBaseComponent implements OnDestroy {
  protected destroyed$ = new ReplaySubject(1);
  private abortController = new AbortController();
  private timer: NodeJS.Timeout | null = null;
  @Input('timeout') timeout?: number;

  constructor(
    public commonService: CommonService
  ) { }

  autoDetectOTP(flag: string = '') {
    const options: CredentialRequestOptions = {
      otp: { transport: ['sms'] },
      signal: this.abortController.signal
    }
    navigator.credentials?.get(options).then((otp: any) => {
      this.commonService.arrivedOTP.next({ code: otp.code, flag: flag });
    }).catch(err => {
      console.log(err);
    });
    if (this.timeout) {
      this.timer = setTimeout(() => {
        this.abortController.abort();
      }, this.timeout)
    }
  }

  ngOnDestroy(): void {
    this.abortController.abort();
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.commonService.arrivedOTP.next({ code: null, flag: '' });
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}

