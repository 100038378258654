<mat-dialog-title>
    <span> QR Code </span>
</mat-dialog-title>

<mat-dialog-content>
    <div class="qrcode">
        <app-generate-qr-code [qrDetails]="qrDetails" [isShowDownload]="true"></app-generate-qr-code>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button type="button" mat-dialog-close class="btn btn-default">
        Cancel
    </button>
</mat-dialog-actions>