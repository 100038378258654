import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {
  @Input() pdfUrl = '';
  @Input() pdfConfig = {};

  defaultConfig: any = {
    rotation: 0,
    originalSize: true,
    showAll: true,
    fitToPage: true,
    zoom: 0,
    zoomScale: 'page-width',
    stickToPage: false,
    renderText: true,
    externalLinkTarget: 'blank',
    autoResize: true,
    showBorders: false,
    styles: {
      width: '100%',
      height: '600px'
    },
    className: ''
  };

  ngOnInit(): void {
    this.defaultConfig = {
      ...this.defaultConfig,
      ...this.pdfConfig
    }
  }
}
