import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, from, BehaviorSubject } from 'rxjs';
import Peer, { MediaConnection } from 'peerjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    public chatDocuments = new BehaviorSubject({ is_get_docs: false, is_change_count: false });

    private activeCalls = new BehaviorSubject<MediaConnection[]>([]);
    public activeCallList = this.activeCalls.pipe(map((x) => Object.values(x)));

    private mediaStream$!: Observable<MediaStream>;

    pId: any = null;
    private peer: Peer | any;

    constructor(
        private socket: Socket
    ) { }

    /**
     * Establised socket connection
     */
    connectSocket() {
        this.socket.connect();
    }

    /**
     * Add user in chat
     * @param userId user id
     */
    addUserInChat(userId: any) {
        this.socket.emit('add-user', userId);
    }

    /**
     * Send message
     * @param request request to send message
     */
    sendMessage(request: any) {
        this.socket.emit('send-msg', request);
    }

    /**
     * Get message
     */
    getMessage() {
        return this.socket.fromEvent('msg-recieve');
    }

    /**
     * Update loan application status
     */
    updateLoanAppStatus(request: any) {
        this.socket.emit('update-app-status', request);
    }

    /**
     * Get loan application status
     */
    getLoanAppStatus() {
        return this.socket.fromEvent('get-app-status');
    }

    /**
     * Check cibil report and take action
     */
    actionOnCibil(request: any) {
        this.socket.emit('action-on-cibil', request);
    }

    /**
     * Get CIBIL report action
     */
    getActionOnCibil() {
        return this.socket.fromEvent('get-action-on-cibil');
    }

    /**
     * Make video call for KYC
     */
    makeVideoKycCall(request: any) {
        this.socket.emit('video-kyc-call', request);
    }

    /**
     * Get video call for KYC
     */
    getVideoKycCall() {
        return this.socket.fromEvent('get-video-kyc-call');
    }

    /**
     * Accept / Reject video call for KYC
     */
    actionOnVideoKycCall(request: any) {
        this.socket.emit('action-on-video-kyc-call', request);
    }

    /**
     * Get action video call for KYC
     */
    getActionOnVideoKycCall() {
        return this.socket.fromEvent('get-action-for-call');
    }


    // ************************** VIDEO CALL ******************************
    addUserInCall(call: MediaConnection) {
        call.on('close', () => this.removeUserFromCall(call));
        call.on('error', () => this.removeUserFromCall(call));

        // hack... to detect a broken connection.
        // Seems PeerJS has no proper wrapper for this case.
        call.peerConnection.onconnectionstatechange = () => {
            if (call.peerConnection.iceConnectionState === 'disconnected') {
                this.removeUserFromCall(call);
            }
        };

        this.activeCalls.next([...this.activeCalls.value, call]);
    }

    removeUserFromCall(call: any) {
        const filteredCalls = this.activeCalls.value.filter(
            (c) => c.connectionId !== call.connectionId
        );
        this.activeCalls.next(filteredCalls);
    }

    public getUserMedia(): Observable<MediaStream> {
        if (this.mediaStream$) {
            console.log('cached stream');
            return this.mediaStream$;
        }

        const constraints = { audio: true, video: true };
        this.mediaStream$ = from(
            navigator.mediaDevices.getUserMedia(constraints)
        ).pipe(shareReplay(1));

        return this.mediaStream$;
    }

    // Peer to Peer Func.
    setupCall() {
        this.peer = new Peer();
        this.peer.on('open', (x: any) => (this.pId = x));
        this.peer.on('call', (call: MediaConnection) => {
            this.getUserMedia().subscribe((mediaStream: any) => {
                call.answer(mediaStream); // Answer the call with an A/V stream.
                this.addUserInCall(call);
            });
        });
    }

    call(targetId: string) {
        this.getUserMedia().subscribe((x) => {
            const call: MediaConnection = this.peer.call(targetId, x);
            this.addUserInCall(call);
        });
    }
}
