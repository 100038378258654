import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RouteNames, StorageKey } from '../../_constants';
import { CommonService } from '../../_services';

@Injectable({
    providedIn: 'root',
})

export class AdminPreventAccess implements CanActivate {

    constructor(
        private router: Router,
        private commonService: CommonService
    ) { }

    canActivate() {
        const token = this.commonService.getLocalStorage(StorageKey.FRONT_TOKEN);
        if (!token) {
            return true;
        } else {
            this.router.navigate([RouteNames.HOME]);
            return false;
        }
    }
}
