<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<ng-container [ngTemplateOutlet]="deleteSearch"></ng-container>

<div class="mat-elevation-z8 common-table">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngIf="btnConfig.isShowCheckBox" matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef class="select-record">
                <mat-checkbox (change)="selectRecords($event)" [(ngModel)]="isCheckAll"
                    name="isCheckAll"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="select-record">
                <mat-checkbox (change)="selectRecords($event)" [value]="row.id" [(ngModel)]="row.is_checked"
                    name="is_checked"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container *ngFor="let columns of tableColumns" [matColumnDef]="columns.key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{columns.title}} </th>
            <td mat-cell *matCellDef="let row">
                <container-element [ngSwitch]="columns.key">
                    <ng-container [ngTemplateOutlet]="showRowCol"
                        [ngTemplateOutletContext]="{row, columns}"></ng-container>
                </container-element>
            </td>
        </ng-container>

        <ng-container *ngIf="btnConfig.isShowAction" matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let row">
                <ng-container [ngTemplateOutlet]="actionBtn" [ngTemplateOutletContext]="{row}"></ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <ng-container [ngTemplateOutlet]="showPagination"></ng-container>
</div>

<!-- 
    - Page header
    - Page title
    - Add new record button
-->
<ng-template #pageHeader>
    <app-page-header [btnConfig]="btnConfig" (onButtonClick)="onActionClick($event)"></app-page-header>
</ng-template>

<!-- 
    - Search record from table
    - Delete selected records
-->
<ng-template #deleteSearch>
    <div class="d-flex align-items-center justify-content-between">
        <div class="w-50">
            <button matTooltip="Delet" [hidden]="selectedRecords.length === 0" class="btn btn-danger mr-2" type="button"
                (click)="dialogConfirmation(selectedRecords)">
                <i class="fa fa-trash"></i> {{actions.DELETE}}
            </button>

            <button *ngIf="btnConfig.isShowExport" matTooltip="Export" class="btn btn-primary mr-2" type="button"
                (click)="onActionClick(actions.EXPORTS)">
                Export All
            </button>
        </div>

        <div class="w-50">
            <mat-form-field class="table-search">
                <mat-label>Search</mat-label>
                <input matInput (ngModelChange)="debounceTime.next($event)" [(ngModel)]="searchText"
                    placeholder="Search" #input />
            </mat-form-field>
        </div>
    </div>
</ng-template>

<!-- 
    - Show row and columns based on condition
 -->
<ng-template #showRowCol let-row="row" let-columns="columns">
    <container-element [ngSwitch]="columns.key">
        <ng-container *ngSwitchCase="'date_of_birth'">
            {{ row.date_of_birth | date: 'dd-MM-yyyy'}}
        </ng-container>
        <ng-container *ngSwitchCase="'role'">
            {{ row.role | AdminRole}}
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{
            row[columns.key] ? (row[columns.key]?.toString().slice(0, 50) +
            (row[columns.key]?.length > 50 ? '...' : '')) : 'N/A'
            }}
        </ng-container>
    </container-element>
</ng-template>

<!--
    - Show action buttons
-->
<ng-template #actionBtn let-row="row">
    <a class="btn btn-success mb-2 mt-2 mr-2" (click)="onActionClick(actions.UPDATE, row)" matTooltip="Edit">
        <i [class]="faIcon.PENCIL"></i>
    </a>

    <a class="btn btn-danger mb-2 mt-2 mr-2" (click)="dialogConfirmation(row)" matTooltip="Delete">
        <i [class]="faIcon.DELETE"></i>
    </a>
</ng-template>

<!--
    - Pagination
-->
<ng-template #showPagination>
    <tr *ngIf="noDataFound" class="no-data-found">
        <td> {{noDataFound}}</td>
    </tr>

    <app-pagination [isSHowPagination]="isSHowPagination" [pageConfig]="pageConfig"
        (onPageChanged)="onPageChanged.emit($event)"></app-pagination>
</ng-template>