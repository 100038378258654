import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { API } from '../_constants';
import { HttpClientService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class HospitalService {

  constructor(
    private httpClientService: HttpClientService
  ) { }

  /**
   * Get Hospitals
   * @param request request data
   * @returns res from api
   */
  getHospital(req?: any) {
    let url = environment.endpoint + API.HOSPITAL_GET;
    if (req) {
      if (req.currentPage) {
        url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
        if (req.keyword) {
          url += `&keyword=${req.keyword}`;
        }
      }
    }
    return this.httpClientService.get(url);
  }

  /**
   * Save Hospital
   * @param request request data
   * @returns res from api
   */
  saveHospital(request: any) {
    const url = environment.endpoint + API.HOSPITAL_SAVE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Delete Hospital
   * @param request request data
   * @returns res from api
   */
  deleteHospital(request?: any) {
    const url = environment.endpoint + API.HOSPITAL_DELETE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Export Hospitals
   * @param request request data
   * @returns res from api
   */
  exportHospitals(request?: any) {
    const url = environment.endpoint + API.HOSPITAL_EXPORT;
    return this.httpClientService.post(url, request);
  }

  /**
   * Get Hospitals
   * @param request request data
   * @returns res from api
   */
  getHospitalDetails(req?: any) {
    let url = environment.endpoint + API.HOSPITAL_DETAILS_GET;
    if (req) {
      if (req.currentPage) {
        url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
        if (req.keyword) {
          url += `&keyword=${req.keyword}`;
        }
      }
    }
    return this.httpClientService.get(url);
  }

  /**
   * Save Hospital
   * @param request request data
   * @returns res from api
   */
  saveHospitalDetails(request: any) {
    const url = environment.endpoint + API.HOSPITAL_DETAILS_SAVE;
    const formData: any = new FormData();
    formData.append('name_of_clinic', request.name_of_clinic);
    formData.append('address_hospital', request.address_hospital);
    formData.append('c_form_number', request.c_form_number);
    formData.append('mci', request.mci);
    formData.append('no_of_beds', request.no_of_beds);
    formData.append('ownership_type', request.ownership_type);
    formData.append('cin', request.cin);
    formData.append('doctor_name', request.doctor_name);
    formData.append("treatment_type_id", JSON.stringify(request.treatment_type_id));
    formData.append('c_form_image', request.c_form_image);

    if (request.id) {
      formData.append('id', request.id);
    }

    return this.httpClientService.post(url, formData);
  }

  /**
   * Delete Hospital
   * @param request request data
   * @returns res from api
   */
  deleteHospitalDetails(request?: any) {
    const url = environment.endpoint + API.HOSPITAL_DETAILS_DELETE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Verify MCI no
   * @param request request data
   * @returns res from api
   */
  verifyMCINo(request?: any) {
    const url = environment.endpoint + API.HOSPITAL_MCI_VERIFICATION;
    return this.httpClientService.post(url, request);
  }

  /**
   * Get Hospital inquires
   * @param req request data
   * @returns res from api
   */
  getHospitalInquiries(req?: any) {
    let url = environment.endpoint + API.HOSPITAL_INQUIRY_GET;
    if (req) {
      if (req.currentPage) {
        url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
        if (req.keyword) {
          url += `&keyword=${req.keyword}`;
        }
      }
    }
    return this.httpClientService.get(url);
  }
}
