import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { API } from '../_constants';
import { HttpClientService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentTermsService {

  constructor(
    private httpClientService: HttpClientService
  ) { }

  /**
   * Get payment terms
   * @param req request data
   * @returns res from api
   */
  getPaymentTerms(req?: any) {
    let url = environment.endpoint + API.PAYMENT_TERMS_GET;
    if (req) {
      if (req.currentPage) {
        url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
        if (req.keyword) {
          url += `&keyword=${req.keyword}`;
        }
      }
    }
    return this.httpClientService.get(url);
  }

  /**
   * Save payment terms
   * @param request request data
   * @returns res from api
   */
  savePaymentTerms(request: any) {
    const url = environment.endpoint + API.PAYMENT_TERMS_SAVE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Delete Payment terms
   * @param request request data
   * @returns res from api
   */
  deletePaymentTerms(request?: any) {
    const url = environment.endpoint + API.PAYMENT_TERMS_DELETE;
    return this.httpClientService.post(url, request);
  }
}
