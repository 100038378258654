<div *ngIf="!isAcceptedCall && !data.flag" class="incoming-call">
    <div class="flex flex-column items-center justify-center wrapper-caller-info">
        <section class="caller-info flex flex-column items-center flex-auto justify-center">
            <div class="circle avatar-caller mb4">
                <div class="ripple-wave">
                    <div class="ripple-wave-2"></div>
                </div>
                <img class="" src="../../../assets/images/logo.png" alt="">
            </div>
            <h1 class="mb2">
                {{ data.flag ? data.personal_info[0].name : data.admin_name}}
            </h1>
            <!-- <h3>
                {{data.personal_info[0].phone_no}}
            </h3> -->
        </section>
        <section class="call-buttons flex justify-between">
            <div class="btn-floating-label" (click)="acceptOrRejectCall(0)">
                <a class="btn-floating btn-jumbo waves-effect waves-light danger" id="reject"><i
                        class="material-icons">phone</i></a>
                <h4 for="reject">End</h4>
            </div>
            <div *ngIf="!data.flag" class="btn-floating-label" (click)="acceptOrRejectCall(1)">
                <a class="btn-floating btn-jumbo waves-effect waves-light success" id="accept"><i
                        class="material-icons">phone</i></a>
                <h4 for="reject">Accept</h4>
            </div>
        </section>
    </div>
</div>

<div *ngIf="isAcceptedCall || data.flag">
    <div class="main-wrapper">
        <div id="video-wrapper">
            <ng-container *ngIf="chatService.activeCallList | async as calls">
                <ng-container *ngFor="let call of calls">
                    <app-video-frame [mediaStream]="call.remoteStream" [showControls]="false"
                        [data]="data"></app-video-frame>
                </ng-container>
            </ng-container>
            <app-video-frame [mediaStream]="homeStream" [data]="data"
                (onCallCut)="onCallAction($event)"></app-video-frame>
        </div>
    </div>
</div>