import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-view-base64-files',
  templateUrl: './view-base64-files.component.html',
  styleUrls: ['./view-base64-files.component.scss']
})
export class ViewBase64FilesComponent {
  @Input() filePreview: any = [];
  @Input() className: string = 'subject-to-send-docs';
  @Output() removeSelectedFile: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Remove selected file from array
   * @param index index
   */
  removeFile(index: number) {
    this.removeSelectedFile.emit(index);
  }
}
