import { environment } from "src/environments/environment";
import { FAICONS } from ".";

export enum RouteNames {
    LOGIN = '/auth/login',
    LOAN_OPTION = '/auth/loan-options',
    PATIENT_SIGNUP = '/auth/patient/signup',
    CIBIL_MESSAGE = '/auth/cibil-message',
    VALIDATE_USER = '/auth/validate-user',
    HOME = '/home',
    MY_LOAN_APPLICATIONS = '/my-applications',
};

export enum AdminRoutes {
    BLANK = '',
    ADMIN_LOGIN = '/admin/auth/login',
    DASHBOARD = '/admin/dashboard',

    USERS = '/admin/users',
    USER_ADD = '/admin/users/add',
    USER_EDIT = '/admin/users/edit/',

    HOSPITALS = '/admin/hospitals',

    HOSPITAL_DETAILS = '/admin/hospital-details',

    TREATMENT_TYPE = '/admin/treatment-type',
    TREATMENTS = '/admin/treatments',

    PAYMENT_TERMS = '/admin/payment-terms',

    LOAN_APPLICATIONS = '/admin/loan/applications',

    DOCUMENTS_TYPE = '/admin/documents-type',

    PATIENT_ON_BOARDING = '/admin/patient-on-boarding',
};

export enum MenuName {
    HOME = 'Home',

    DASHBOARD = 'Dashboard',
    MASTERS = 'Masters',
    DOCUMENTS_TYPE = 'Documents Type',
    PAYMENT_TERMS = 'Payment Terms',
    TREATMENT_TYPE = 'Treatment Type',
    TREATMENTS = 'Treatments',
    LOAN_APPLICATIONS = 'Loan Applications',
    HOSPITALS = 'Hospitals',
    HOSPITAL_DETAILS = 'Hospital Details',
    USERS = 'Users',
    PATIENT_ON_BOARDING = 'Patient On Boarding'
};

export const sideMenu = [
    { title: MenuName.HOME, route: RouteNames.HOME, icon: FAICONS.HOME, has_child: false },
];

export const PATIENT = [
    sideMenu[0],
];

// ADMIN MENUS
export const AdminMenus = [
    { title: MenuName.DASHBOARD, route: AdminRoutes.DASHBOARD, icon: FAICONS.HOME, has_child: false },
    {
        id: 1, title: MenuName.MASTERS, route: AdminRoutes.BLANK, icon: FAICONS.FOLDER, has_child: true,
        subMenu: [
            { title: MenuName.DOCUMENTS_TYPE, route: AdminRoutes.DOCUMENTS_TYPE, icon: FAICONS.FILE, has_child: false },
            { title: MenuName.PAYMENT_TERMS, route: AdminRoutes.PAYMENT_TERMS, icon: FAICONS.CREDIT_CARD, has_child: false },
            { title: MenuName.TREATMENT_TYPE, route: AdminRoutes.TREATMENT_TYPE, icon: FAICONS.MEDKIT, has_child: false },
            { title: MenuName.TREATMENTS, route: AdminRoutes.TREATMENTS, icon: FAICONS.PLUS_CIRCLE, has_child: false },
            { title: MenuName.HOSPITALS, route: AdminRoutes.HOSPITALS, icon: FAICONS.HOSPITAL, has_child: false },
        ]
    },
    { title: MenuName.LOAN_APPLICATIONS, route: AdminRoutes.LOAN_APPLICATIONS, icon: FAICONS.ADDRESS_BOOK, has_child: false },
    { title: MenuName.HOSPITAL_DETAILS, route: AdminRoutes.HOSPITAL_DETAILS, icon: FAICONS.HOSPITAL, has_child: false },
    { title: MenuName.USERS, route: AdminRoutes.USERS, icon: FAICONS.USERS, has_child: true },
    { title: MenuName.PATIENT_ON_BOARDING, route: AdminRoutes.PATIENT_ON_BOARDING, icon: FAICONS.ID_CARD, has_child: true },
];

// HOSPITAL ADMIN
export const HospitalAdmin = [
    AdminMenus[0],
    AdminMenus[2],
];

export let InnerLinks = {
    DR_QR_LINK: environment.projectURL + RouteNames.LOAN_OPTION,
    APPLICATION_URL: environment.projectURL + RouteNames.MY_LOAN_APPLICATIONS,
};
