import { Pipe, PipeTransform } from '@angular/core';
import { Regex } from '../_constants';

@Pipe({
    name: 'MessagePipe'
})
export class MessagePipe implements PipeTransform {
    transform(message: any): any {

        // Highlight URLs
        if (message.search('https://') !== -1) {
            let findLink: any[] = message.match(Regex.getUrlFromString) || [];
            findLink = [...new Set(findLink)];

            // If Loan Agreement In Progress OR Digital Escrow Initiated
            // then don't open url in new tab.
            // It will open modal for e-sign 
            if (
                message.search('Loan Agreement In Progress') !== -1 ||
                message.search('Digital Escrow Initiated') !== -1
            ) {
                findLink.forEach((item: any) => {
                    message = message.replaceAll(item, `<span class="link-url">${item}</span>`);
                });
            } else {
                findLink.forEach((item: any) => {
                    message = message.replaceAll(item, `<a href="${item}" target="_blank">${item}</a>`);
                });
            }
        }
        return message;
    }
}