import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AdminRoutes, RouteNames, StorageKey } from '../_constants';

@Injectable({
    providedIn: 'root'
})

export class HttpClientService {
    responseData: any;
    returnReponse: any;

    constructor(
        private http: HttpClient,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }

    /**
     * post method to get data from api
     * @param url
     * @param data
     * @returns return response from api
     */
    post(url: any, data: any, headers?: any): Observable<any> {
        this.spinner.show();
        return this.http.post(url, data, headers).pipe(map((res: any) => {
            this.unAuthorized(res);
            return res;
        }), catchError((error: any) => {
            this.spinner.hide();
            return throwError(error);
        }));
    }

    /**
     * get method to get data from api
     * @param url
     * @returns return response from api
     */
    get(url: any, headers?: any): Observable<any> {
        this.spinner.show();
        return this.http.get(url, headers).pipe(map((res: any) => {
            this.unAuthorized(res);
            return res;
        }), catchError((error: any) => {
            this.spinner.hide();
            return throwError(error);
        }));
    }

    /**
     * delete method to delete data from api
     * @param url
     * @returns return response from api
     */
    delete(url: any) {
        this.spinner.show();
        this.returnReponse = new Promise((resolve, reject) => {
            this.http.delete(url).subscribe((res: any) => {
                this.responseData = res;
                this.unAuthorized(res);
                if (this.responseData.status === 'fail') {
                    reject(this.responseData.message = 'some error occure');
                }
                resolve(this.responseData);
            }, (error: any) => {
                this.spinner.hide();
                reject(error);
            });
        });
        return this.returnReponse;
    }

    unAuthorized(res: any) {
        if (res.code === 401) {
            if (this.router.url.search('/admin') !== -1) {
                localStorage.removeItem(StorageKey.ADMIN_DETAILS);
                localStorage.removeItem(StorageKey.ADMIN_TOKEN);
                location.href = AdminRoutes.ADMIN_LOGIN;
            } else {
                localStorage.removeItem(StorageKey.FRONT_TOKEN);
                localStorage.removeItem(StorageKey.USER_INFO);
                location.href = RouteNames.LOGIN;
            }
        }
    }
}