import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';
import { AssetsFile, StorageKey } from '../_constants';
import { environment } from '../../environments/environment';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    public isHideSpinner = new BehaviorSubject({ is_hide_spinner: true });
    public setTableData = new BehaviorSubject({});
    public userData = new BehaviorSubject({});
    public arrivedOTP = new BehaviorSubject({ code: null, flag: '' });
    isCheckAll = false;
    selectedRecords: any = [];
    audio = new Audio();

    constructor(
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private spinner: NgxSpinnerService
    ) { }

    /**
     * Emit value for spinner
     * Default value is true
     * @param value boolean value
     */
    emitValueForSpinner(value: boolean = true) {
        this.isHideSpinner.next({ is_hide_spinner: value });
    }

    /**
     * Show toast message
     * @param res res data
     */
    toaster(res: any) {
        if (res.success) {
            this.toastr.success(res.message);
        } else {
            this.toastr.error(res.message);
        }
    }

    /**
     * Go back to previous page
     */
    goBack() {
        history.back();
    }

    /**
     * Multi Select record
     * @param event change event object
     * @param data res data
     */
    multiSelect(event: any, data: any) {
        this.isCheckAll = false;
        this.selectedRecords = [];
        data.forEach((items: any) => {
            if (event.source.name === 'isCheckAll') {
                items.is_checked = event.source.checked;
            } else {
                if (event.source.value == items.id) {
                    items.is_checked = event.source.checked;
                }
            }
            if (items.is_checked) {
                this.selectedRecords.push(items.id);
            }
        });
        this.isCheckAll = data.length === this.selectedRecords.length;
        return data;
    }

    /**
     * Change date format 
     * @param date date
     * @param format  date format
     */
    changeDateFormat(date: any = new Date(), format = 'yyyy-MM-dd') {
        return this.datePipe.transform(date, format);
    }

    /**
     * Check file extentions
     */
    isInArray(array: any, word: any) {
        return array.indexOf(word.toLowerCase()) > -1;
    }

    /**
     * Download file from URL
     * @param url url
     * @param filename file name with extention
     */
    downloadFileFromUrl(url: string, filename: string) {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        return link;
    }

    /**
     * Generate random numbers
     */
    generateRandomNo() {
        return Math.floor(Math.random() * 10000000000);
    }

    /**
     * Scroll Page to top
     */
    scrollTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    /**
     * Scroll Page to bottom
     */
    scrollBottom(scrollHeight?: any) {
        const height = scrollHeight || document.body.scrollHeight;
        window.scrollTo({ top: height, behavior: 'smooth' });
    }

    /**
     * Scroll message area
     */
    scrollMsgArea() {
        $(".msg-area").animate({ scrollTop: 20000000 });
    }

    downloadFile(item: string) {
        this.spinner.show();
        this.http.get(item, { responseType: 'blob', reportProgress: true, observe: 'events' })
            .subscribe((event: any) => {
                this.spinner.hide();
                if (event.type === HttpEventType.Response) {
                    const newFile: any = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event['body']));
                    this.download({
                        imageSrc: newFile,
                        filename: item.split('/').reverse()[0]
                    });
                }
            }, error => this.spinner.hide());
    }

    download(params: any) {
        let anchorTag: any = document.createElement('a');
        anchorTag.href = params.imageSrc.changingThisBreaksApplicationSecurity;
        anchorTag.download = params.filename;
        anchorTag.click();
    }

    playRingtone(isPlay: boolean) {
        if (isPlay) {
            this.audio.src = AssetsFile.RINGTONE;
            this.audio.load();
            this.audio.play();
        } else {
            this.audio.pause();
            this.audio.currentTime = 0;
        }
    }

    /**
     * Set data in local storage
     * @param key key
     * @param value value
     */
    setLocalStorage(key: string, value: any) {
        localStorage.setItem(key, this.encryptUsingAES256(value));
    }

    /**
     * Get data from local storage
     * @param key key
     */
    getLocalStorage(key: string) {
        if (!localStorage.getItem(key)) {
            return;
        }
        let encryptedData = localStorage.getItem(key);
        let decryptedData = this.decryptUsingAES256(encryptedData);
        decryptedData = JSON.parse(decryptedData);
        return decryptedData;
    }

    /**
     * Encrypt data
     * @param value value
     */
    encryptUsingAES256(value: any) {
        let originalData = JSON.stringify(value);
        let _key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
        let _iv = CryptoJS.enc.Utf8.parse(environment.encryptIV);

        let encrypted: any = CryptoJS.AES.encrypt(originalData, _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
        return encrypted;
    }

    /**
     * Decrypt data
     * @param encryptedData value
     */
    decryptUsingAES256(encryptedData: any) {
        let _key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
        let _iv = CryptoJS.enc.Utf8.parse(environment.encryptIV);

        let decrypted: any = CryptoJS.AES.decrypt(encryptedData, _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        return decrypted;
    }

    /**
     * Remove front storage
     */
    removeFrontStorage() {
        localStorage.removeItem(StorageKey.FRONT_TOKEN);
        localStorage.removeItem(StorageKey.USER_INFO);
    }

    /**
     * Get host name
     */
    getHost() {
        return location.origin;
    }
}