import { Pipe, PipeTransform } from '@angular/core';
import { Income } from '../_interface';

@Pipe({
    name: 'Income'
})
export class IncomePipe implements PipeTransform {
    transform(incomeId: number): any {
        return Income.filter((item: any) => item.id === incomeId)[0].title;
    }
}
