import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API } from '../_constants/api';
import { HttpClientService } from './index';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private httpClientService: HttpClientService
    ) { }

    /**
     * Get patients
     * @param req request data
     * @returns res from api
     */
    getPatients(req?: any) {
        let url = environment.endpoint + API.USER_GET;
        if (req) {
            if (req.currentPage) {
                url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
                if (req.keyword) {
                    url += `&keyword=${req.keyword}`;
                }
            }
        }
        return this.httpClientService.get(url);
    }

    /**
     * Delete patient
     * @param request request data
     * @returns res from api
     */
    deletePatient(req: any) {
        const url = environment.endpoint + API.USER_DELETE;
        return this.httpClientService.post(url, req);
    }

    // ------------------ ADMIN API --------------------------- //
    /**
   * Login api call
   * @param request request data
   * @returns response from api
   */
    adminLogin(request: any) {
        const url = environment.endpoint + API.ADMIN_LOGIN;
        return this.httpClientService.post(url, request);
    }

    /**
     * Get users
     * @param request request data
     * @returns res from api
     */
    getAdmins(req: any) {
        let url = environment.endpoint + API.ADMIN_GET;
        if (req) {
            if (req.currentPage) {
                url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
                if (req.keyword) {
                    url += `&keyword=${req.keyword}`;
                }
            }

            if (req.id) {
                url += `?id=${req.id}`;
            }
        }
        return this.httpClientService.get(url);
    }

    /**
     * Save user
     * @param request request data
     * @returns res from api
     */
    saveAdmin(request: any) {
        const url = environment.endpoint + API.ADMIN_SAVE;
        return this.httpClientService.post(url, request);
    }

    /**
     * Delete user
     * @param request request data
     * @returns res from api
     */
    deleteAdmin(request?: any) {
        const url = environment.endpoint + API.ADMIN_DELETE;
        return this.httpClientService.post(url, request);
    }

    /**
     * Validate user
     * @param req req params
     * @returns res from api
     */
    validateUser(req: any) {
        const url = environment.endpoint + API.VALIDATE_USER;
        return this.httpClientService.post(url, req);
    }
}