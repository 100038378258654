<mat-dialog-title>
    <span> E-Sign </span>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
    <ng-container [ngTemplateOutlet]="viewPDF"></ng-container>
    <ng-container [ngTemplateOutlet]="viewOTP"></ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <button type="button" class="btn btn-default" (click)="closeModal()">
        Close
    </button>
</mat-dialog-actions>

<ng-template #viewPDF>
    <div [hidden]="isShowOTP">
        <div>
            <app-view-pdf [pdfUrl]="data.url" [pdfConfig]="{showBorders: true}"></app-view-pdf>
        </div>
        <div class="form-group mt-4">
            <mat-checkbox [(ngModel)]="signDocs.is_esign_temrs_accept" (change)="onTermsClick()">
                I have read and agreed to the Terms and Conditions
                <span class="text-red"> *</span>
            </mat-checkbox>
        </div>
    </div>
</ng-template>


<ng-template #viewOTP>
    <div [hidden]="!isShowOTP">
        <div class="form">
            <div class="row">
                <canvas #canvas
                        (touchstart)="startDrawing($event)"
                        (touchmove)="moved($event)"></canvas>
                <label for="fancy-textarea">Please Add your signature here</label>
              </div>             
              <button class="clear-btn" (click)="clearPad()">Clear</button> 
           
            <div class="row">
                <div class="form-group input-group">
                    <input class="form-control" placeholder="Enter Otp" [(ngModel)]="signDocs.otp" />
                    <button type="button" class="btn btn-primary" (click)="verifyOtpForTerms()"
                        [disabled]="!signDocs.otp">
                        Verify OTP
                    </button>
                </div>
                <p>If you not get OTP? <a (click)="onTermsClick()" class="resend-otp">Resend OTP</a></p>
            </div>
        </div>
    </div>
</ng-template>