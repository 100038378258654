
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { API } from '../_constants';
import { HttpClientService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TreatmentService {

  constructor(
    private httpClientService: HttpClientService
  ) { }

  /**
   * Get Treatment Type
   * @param req request data
   * @returns res from api
   */
  getTreatmentType(req?: any) {
    let url = environment.endpoint + API.TREATMENT_TYPE_GET;
    if (req) {
      if (req.currentPage) {
        url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
        if (req.keyword) {
          url += `&keyword=${req.keyword}`;
        }
      }
    }
    return this.httpClientService.get(url);
  }

  /**
   * Save Treatment Type
   * @param request request data
   * @returns res from api
   */
  saveTreatmentType(request: any) {
    const url = environment.endpoint + API.TREATMENT_TYPE_SAVE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Delete Treatment Type
   * @param request request data
   * @returns res from api
   */
  deleteTreatmentType(request?: any) {
    const url = environment.endpoint + API.TREATMENT_TYPE_DELETE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Get Treatment
   * @param request request data
   * @returns res from api
   */
  getTreatment(req?: any) {
    let url = environment.endpoint + API.TREATMENT_GET;
    if (req) {
      if (req.currentPage) {
        url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
        if (req.keyword) {
          url += `&keyword=${req.keyword}`;
        }
      }

      if (req?.treatment_type_id) {
        url += `?treatment_type_id=${req.treatment_type_id}`;
      }
    }
    return this.httpClientService.get(url);
  }

  /**
   * Save Treatment
   * @param request request data
   * @returns res from api
   */
  saveTreatment(request: any) {
    const url = environment.endpoint + API.TREATMENT_SAVE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Delete Treatment
   * @param request request data
   * @returns res from api
   */
  deleteTreatment(request?: any) {
    const url = environment.endpoint + API.TREATMENT_DELETE;
    return this.httpClientService.post(url, request);
  }
}
