export const Pagination = { currentPage: 1, pageSize: 10, totalItems: 0 };

export const PageSize = [10, 20, 30];

export const agmAPIKey = 'AIzaSyCK1scJhlf3k-Lu5oSWbVTmg-St37_QRCI';

export const NoDataFound = 'No Data Found';

export enum AssetsFile {
    LOGO = '../../assets/images/logo.png',
    CASH = '../../assets/images/cash.png',
    APPLY = '../../assets/images/apply.png',
    ADMIN = '../../assets/plugins/dist/img/admin.png',
    PATIENT = '../../assets/plugins/dist/img/user.jpg',
    PATIENT_OPTION = '../../assets/images/patientlogin.png',
    DOCTOR_OPTION = '../../assets/images/doctorlogin.png',
    CONGRATULATION = '../../assets/images/congratulation.png',
    SORRY = '../../assets/images/sorry.png',
    PENDING = '../../assets/images/pending.png',
    UPLOAD = '../../assets/images/upload.png',
    RINGTONE = '../../assets/images/ringtone.mp3'
};

export enum FAICONS {
    HOME = 'fa fa-home',
    FOLDER = 'fa fa-folder',
    FILE = 'fa fa-file',
    CREDIT_CARD = 'fa fa-credit-card',
    MEDKIT = 'fa fa-medkit',
    ADDRESS_BOOK = 'fa fa-address-book',
    HOSPITAL = 'fa fa-hospital',
    USERS = 'fa fa-users',
    PLUS_CIRCLE = 'fa fa-plus-circle',
    COPY = 'fa fa-copy',
    ID_CARD = 'fa fa-id-card',
    PLUS = 'fa fa-plus',
    DELETE = 'fa fa-trash',
    PENCIL = 'fas fa-pencil-alt'
};

export const DeleteMsg = {
    width: '450px',
    height: '405px',
    data: {
        title: 'Are you sure want to delete it?',
        message: 'It will delete permanently!',
        okBtnLabel: 'Yes',
        cancelBtnLabel: 'No',
    }
};

export enum StorageKey {
    FRONT_TOKEN = 'FRONT_END_TOKEN',
    USER_INFO = 'USER_INFO',
    PATIENT_ON_BOARDING = 'PATIENT_ON_BOARDING',
    USERNAME = 'USERNAME',
    ADMIN_TOKEN = 'ADMIN_TOKEN',
    ADMIN_DETAILS = 'ADMIN_DETAILS',
    LOAN_APPLICATION_URL = 'LOAN_APPLICATION_URL',
};

export enum ROLE {
    ADMIN = 'Admin',
    PATIENT = 'Patient'
};

export enum Actions {
    ADD = 'Add',
    UPDATE = 'Update',
    DELETE = 'Delete',
    VIEW = 'View',
    EXPORTS = 'Export'
};


export const WhatsappLink = 'https://api.whatsapp.com/send?phone=';
export const HealthFinit = 'https://healthfinit.com/';
export const TermsCondition = 'https://storage.googleapis.com/rxtra/1683631473705_drpre.pdf';
export const GoogleCloud = 'https://storage.googleapis.com/rxtra';

export const Regex = {
    imgExt: ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'],
    imgExtMsg: 'Only jpg, jpeg, png images are allowed',
    docExt: ['pdf', 'PDF', 'DOC', 'doc', 'docx', 'DOCX'],
    docExtMsg: 'Only pdf, doc, docx type document are allowed',
    docImgExt: ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'pdf', 'PDF', 'DOC', 'doc', 'docx', 'DOCX', 'csv', 'CSV', 'xlsx', 'XLSX'],
    docImgExtMsg: 'Only JPG, PNG, JPEG, PDF, DOC, DOCX, CSV, XLSX files are allowed',
    imgPDF: ['jpeg', 'jpg', 'png', 'pdf'],
    imgPDFMsg: 'Only jpeg, jpg, png and pdf allowed',

    // Other Regex
    pan: /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/,
    phoneNoOnly: /[0-9]{10}/,
    pincode: /^[0-9]{6}$/,
    otp: /^[0-9]{6}$/,
    alphabetOnly: /^[a-zA-Z \-\']+$/,
    ifsccode: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    numberOnly: /^([0-9]*)$/,
    getUrlFromString: /\bhttps?:\/\/\S+/gi,
    spaceAfterFourChar: /.{1,4}/g,
    email: /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
};