<div id="section-to-print">
    <qrcode #parent [elementType]="qrDetails.elementType" id="print" [qrdata]="qrDetails.qrdata"
        [errorCorrectionLevel]="qrDetails.errorCorrectionLevel" [scale]="qrDetails.scale" [width]="qrDetails.width"
        [allowEmptyString]="true" [colorDark]="qrDetails.colorDark" [ariaLabel]="qrDetails.ariaLabel"
        [title]="qrDetails.title">
    </qrcode>
</div>

<div *ngIf="isShowDownload" class="d-flex mt-3">
    <button class="btn btn-primary" (click)="saveAsImage(parent)">
        Download QR Code
    </button>
    <button class="btn btn-default ml-3" (click)="printQR()">Print QR</button><br>
</div>