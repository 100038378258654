import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '../_interface';

@Pipe({
    name: 'GenderType'
})
export class GenderPipe implements PipeTransform {
    transform(value: any): any {
        return Gender.filter((item: any) => item.id == value)[0].title;
    }
}