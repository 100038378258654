import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generate-qr-code',
  templateUrl: './generate-qr-code.component.html',
  styleUrls: ['./generate-qr-code.component.scss']
})
export class GenerateQrCodeComponent implements OnInit {
  @Input() qrDetails: any;
  @Input() isShowDownload: boolean = false;
  printSection: any;
  constructor() { }

  ngOnInit(): void {
  }

  printQR() {
    window.print();
  }

  /**
   * 
   * @param qrimg 
   */
  saveAsImage(parent: any) {
    let parentElement = null;
    if (this.qrDetails.elementType === "canvas") {
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png");
    } else if (this.qrDetails.elementType === "img" || this.qrDetails.elementType === "url") {
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src;
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.");
    }

    if (parentElement) {
      let blobData = this.convertBase64ToBlob(parentElement);
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Qrcode";
      link.click();
    }
  }

  convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,'); // SPLIT INTO TWO PARTS
    const imageType = parts[0].split(':')[1]; // HOLD THE CONTENT TYPE
    const decodedData = window.atob(parts[1]); // DECODE BASE64 STRING
    const uInt8Array = new Uint8Array(decodedData.length); // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    } // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    return new Blob([uInt8Array], { type: imageType }); // RETURN BLOB IMAGE AFTER CONVERSION
  }
}
