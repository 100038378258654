<div class="chat-bot">
    <div class="chat-header">
        <span *ngIf="isAdmin">{{userDetails.personal_info[0].name}}</span>
        <span *ngIf="isPatient">HealthFinit Chat</span>
    </div>
    <div class="msg-area">
        <div *ngFor="let msg of messages; let ind = index" class="view-msg">
            <div *ngIf="msg.loan_id == loanId && msg.sender_id == senderId" class="sender-msg">
                <div class="show-msg">
                    <ng-container [ngTemplateOutlet]="showMsgs" [ngTemplateOutletContext]="{
                        message: msg,
                        ind: ind
                    }"></ng-container>
                    <ng-container [ngTemplateOutlet]="rightTail"></ng-container>
                </div>
            </div>

            <div *ngIf="msg.loan_id == loanId && msg.sender_id != senderId" class="receiver-msg"
                (click)="eSignDocsModal(msg)">
                <div class="show-msg receiver">
                    <ng-container [ngTemplateOutlet]="showMsgs" [ngTemplateOutletContext]="{
                        message: msg,
                        ind: ind
                    }"></ng-container>
                    <ng-container [ngTemplateOutlet]="leftTail"></ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="input-group send-msg-docs">
        <ng-container [ngTemplateOutlet]="selectDocs"></ng-container>
        <div class="send-input">
            <div class="d-flex">
                <i class="fa fa-paperclip" aria-hidden="true" (click)="docs.click()"></i>
                <i *ngIf="isAdmin" class="fa fa-video" aria-hidden="true" (click)="videoCall('admin')"></i>
                <input type="file" (change)="onSelectFile($event)" multiple #docs hidden>
            </div>

            <textarea rows="1" [(ngModel)]="messageText" class="form-control" placeholder="Type message here"
                required></textarea>

            <div class="input-group-append">
                <button type="submit" class="btn btn-primary" [disabled]="fileObjects.length === 0 && !messageText"
                    (click)="sendMessage()">
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #selectDocs>
    <div>
        <div *ngIf="errorMsg"><span class="text-red">{{errorMsg}}</span></div>
        <div *ngIf="filePreview.length > 0" class="documents">
            <ng-container *ngFor="let file of filePreview; let i = index">
                <div *ngIf="file.type.includes('image')" class="show-docs">
                    <img [src]="file.file" />
                    <i class="fa fa-trash" (click)="removeFile(i)"></i>
                </div>
                <div *ngIf="file.type.includes('pdf')" class="show-docs">
                    <i class="fa fa-file-pdf"></i>
                    <i class="fa fa-trash" (click)="removeFile(i)"></i>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #showMsgs let-message="message" let-ind="ind">
    <div *ngIf="message.documents" class="show-files">
        <div class="docs">
            <div *ngFor="let file of message.documents" class="set-file">
                <div *ngIf="file.toLowerCase().includes('.jpg') || file.toLowerCase().includes('.jpeg') || file.toLowerCase().includes('.png')"
                    class="view-docs">
                    <a [href]="file" data-toggle="lightbox" data-gallery="gallery">
                        <img [src]="file" />
                    </a>
                </div>
                <div *ngIf="file.toLowerCase().includes('.pdf') || file.toLowerCase().includes('_blob')"
                    class="view-docs">
                    <a [href]="file" data-toggle="lightbox" data-gallery="gallery">
                        <i class="fa fa-file-pdf"></i>
                    </a>
                </div>
            </div>
        </div>
        <a (click)="downloadDocs(message.documents)" class="download-file">Download</a>
    </div>
    <div *ngIf="message.message" (click)="eSignDocsModal(message.message)">
        <div [innerHTML]="message.message | MessagePipe"></div>
    </div>
    <small class="time">{{ message.message_send_time | date: 'dd/MM/yyyy hh:mm a'}}</small>
</ng-template>

<ng-template #leftTail>
    <span data-testid="tail-in" data-icon="tail-in" class="tails"><svg viewBox="0 0 8 13" height="13" width="8"
            preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 8 13"
            xml:space="preserve">
            <path opacity="0.13" fill="#fff" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z">
            </path>
            <path fill="#fff" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z">
            </path>
        </svg></span>
</ng-template>

<ng-template #rightTail>
    <span data-testid="tail-out" data-icon="tail-out" class="tails"><svg viewBox="0 0 8 13" height="13" width="8"
            preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 8 13"
            xml:space="preserve">
            <path opacity="0.13" d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"></path>
            <path fill="#d9fdd3" d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"></path>
        </svg></span>
</ng-template>