<mat-dialog-title>
  <span> Confirmation! </span>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
  <div>
    <p><i [class]="data.icon"></i></p>
    <p>{{data.title}}</p>
    <p>{{data.message}}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="data.cancelBtnLabel" type="button" mat-dialog-close class="btn btn-default mr-3">
    {{data.cancelBtnLabel}}
  </button>
  <button *ngIf="data.okBtnLabel" type="submit" class="btn btn-primary" (click)="onSubmitClick()">
    {{data.okBtnLabel}}
  </button>
</mat-dialog-actions>