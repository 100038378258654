import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QRCodeElementType } from 'angularx-qrcode';
import { CommonTableComponent } from '../common-table/common-table.component';
import { InnerLinks } from '../../_constants';

@Component({
  selector: 'app-common-qr',
  templateUrl: './common-qr.component.html',
  styleUrls: ['./common-qr.component.scss']
})
export class CommonQrComponent implements OnInit {
  userDetails: any;
  qrDetails: any = {
    qrdata: InnerLinks.DR_QR_LINK + `?hospitalId=${this.data.hospital_id}`,
    scale: 1,
    width: 250,
    colorDark: "#000000ff",
    ariaLabel: `QR Code image with the following content...`,
    elementType: "canvas" as QRCodeElementType,
    errorCorrectionLevel: "M",
    // QRCodeErrorCorrectionLevel
    title: "Scan to Login to HealthFinit"
  };

  constructor(
    public matDialogRef: MatDialogRef<CommonTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }
}
