import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { API } from '../_constants';
import { HttpClientService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private httpClientService: HttpClientService
  ) { }

  /**
   * Get documents
   * @param req request data
   * @returns res from api
   */
  getDocumnts(req?: any) {
    let url = environment.endpoint + API.DOCUMENTS_TYPE_GET;
    if (req) {
      if (req.currentPage) {
        url += `?currentPage=${req.currentPage}&pageSize=${req.pageSize}`;
        if (req.keyword) {
          url += `&keyword=${req.keyword}`;
        }
      }
    }
    return this.httpClientService.get(url);
  }

  /**
   * Save document
   * @param request request data
   * @returns res from api
   */
  saveDocuments(request: any) {
    const url = environment.endpoint + API.DOCUMENTS_TYPE_SAVE;
    return this.httpClientService.post(url, request);
  }

  /**
   * Delete document
   * @param request request data
   * @returns res from api
   */
  deleteDocuments(request?: any) {
    const url = environment.endpoint + API.DOCUMENTS_TYPE_DELETE;
    return this.httpClientService.post(url, request);
  }
}