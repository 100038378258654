<div>
    <video #videoElement playsinline autoplay [muted]="showControls"
        [id]="showControls ? 'local-video' : 'remote-video'"></video>

    <div class="controls">
        <div (click)="muteMic()">
            <i [ngClass]="['vicon', 'fa', micMuted ? 'fa-microphone-slash' : 'fa-microphone']"></i>
        </div>
        <div (click)="disableCam()">
            <i [ngClass]="['vicon', 'fa', vidMuted ? 'fa-video-slash' : 'fa-video']"></i>
        </div>
        <div class="end-call" (click)="endCall()">
            <i [ngClass]="['vicon', 'fa', 'fa-phone']"></i>
        </div>
    </div>
</div>