import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChatService, CommonService, LoanService } from '../../_services';
import { Regex, RouteNames, StorageKey, TermsCondition } from '../../_constants';
import { LoanStatus, LoanStatusId } from '../../_interface';
import { DigitalSignComponent, VideoChatComponent } from '../index';

@Component({
  selector: 'app-admin-patient-chat',
  templateUrl: './admin-patient-chat.component.html',
  styleUrls: ['./admin-patient-chat.component.scss'],
})
export class AdminPatientChatComponent implements OnInit, AfterViewInit {
  @Input() userDetails: any = {};
  @Input() adminDetails: any = {};
  @Input() isPatient: any = false;
  @Input() isAdmin: any = false;
  @Output() chatDocuments: EventEmitter<any> = new EventEmitter<any>();

  messageText: string = '';
  messages: any = [];
  senderId: any = null;
  receiverId: any = null;
  filePreview: any = [];
  fileObjects: any = [];
  errorMsg: any = '';
  loanId: any = this.activatedRoute.snapshot.queryParams['loanId'];
  documents: any = { sent: [], received: [] };
  loanStatus: any = LoanStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private chatService: ChatService,
    private loanService: LoanService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.senderId = this.adminDetails.admin_uid;
      this.receiverId = this.userDetails.id;
    } else {
      this.senderId = this.userDetails.id;
      this.receiverId = this.userDetails.admin_uid;
      this.openEsignModalByUrl();
    }
    this.socketConnection();
    this.getChatMessages();
    this.filterChatDocuments();
  }

  ngAfterViewInit(): void {
    this.videoCallByScoket();
  }

  openEsignModalByUrl() {
    let url = this.commonService.getLocalStorage(StorageKey.LOAN_APPLICATION_URL);
    if (url && this.userDetails.loan_current_status === LoanStatusId.LOAN_AGREEMENT_IN_PROGRESS) {
      localStorage.removeItem(StorageKey.LOAN_APPLICATION_URL);
      this.eSignDocsModal(
        `Loan Agreement In Progress. Please sign the loan agreement. ${TermsCondition}`
      );
    }
  }

  /**
   * This method will show the incoming video call modal
   */
  videoCallByScoket() {
    this.chatService.getVideoKycCall().subscribe((res: any) => {
      if (res && this.userDetails.id == res.receiver_id) {
        this.commonService.playRingtone(true);
        this.videoCall('', res.peerId);
      }
    });
  }

  /**
   * Established socket connection
   */
  socketConnection() {
    this.addUserInSocket();
    this.chatService.getMessage().subscribe((message: any) => {
      this.messages.push(message);
      this.changeDetectorRef.detectChanges();
      this.commonService.scrollMsgArea();
    });
  }

  /**
   * Get chat messages
   */
  getChatMessages() {
    let request: any = {
      admin_uid: this.userDetails.admin_uid,
      loan_id: String(this.userDetails.id),
    };
    if (request.admin_uid && request.loan_id) {
      this.loanService.adminPatientChatGet(request).subscribe({
        next: (res: any) => {
          if (res.success && res.data) {
            this.messages = res.data;

            if (this.isAdmin) {
              this.chatService.chatDocuments.next({
                is_get_docs: false,
                is_change_count: true,
              });
            }
          } else {
            this.commonService.toaster(res);
          }
        },
        error: (err: any) => {
          console.log(err.statusText);
        },
      });
      this.commonService.emitValueForSpinner();
    }
  }

  /**
   * Select files
   * @param event file obj.
   */
  onSelectFile(event: any) {
    this.fileObjects.push(...event.target.files);
    this.filePreview = [];
    this.errorMsg = '';
    for (let i = 0; i < this.fileObjects.length; i++) {
      const fileExtension = this.fileObjects[i].name.split('.').pop();
      if (this.commonService.isInArray(Regex.imgPDF, fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          this.filePreview.push({
            type: this.fileObjects[i].type,
            file: reader.result as string,
          });
        };
        reader.readAsDataURL(this.fileObjects[i]);
      } else {
        this.fileObjects.splice(i, 1);
        this.errorMsg = Regex.imgPDFMsg;
      }
    }
  }

  /**
   * Remove file from selected files to send in chat
   * @param i index
   */
  removeFile(i: number) {
    this.filePreview.splice(i, 1);
    this.fileObjects.splice(i, 1);
  }

  /**
   * Send message in chat
   */
  sendMessage() {
    if (this.messageText || this.fileObjects.length > 0) {
      let request: any = {
        sender_id: this.senderId,
        receiver_id: this.receiverId,
        message: this.messageText,
        loan_id: this.loanId,
      };

      if (this.fileObjects.length > 0) {
        request.documents = this.fileObjects;
      } else {
        let req = {
          ...request,
          message_send_time: new Date(),
          sender_name: this.senderName,
        };
        this.chatService.sendMessage(req);
        this.messages.push(req);
        this.messageText = '';
        this.commonService.scrollMsgArea();
      }
      this.saveChatMessage(request);
    }
  }

  /**
   * Save messages
   * @param request chat data request
   */
  saveChatMessage(request: any) {
    if (
      (request.read_msg_id && request.read_msg_id.length > 0) ||
      request.loan_id
    ) {
      this.loanService.adminPatientChatSave(request).subscribe((res: any) => {
        if (res.success) {
          if (this.fileObjects.length > 0) {
            this.ifDocumentsSelected(res);
            this.saveSignDocsURL(res.data.documents[0]);
          }
        } else {
          this.commonService.toaster(res);
        }
      });
      if (this.fileObjects.length === 0) {
        this.commonService.emitValueForSpinner();
      }
    }
  }

  /**
   * If Documents selected
   */
  ifDocumentsSelected(res: any) {
    let request: any = {
      id: res.data.id,
      sender_id: this.senderId,
      receiver_id: this.receiverId,
      message: res.data.message,
      message_send_time: res.data.message_send_time,
      loan_id: this.loanId,
      sender_name: this.senderName,
    };

    if (res.data.documents) {
      request.documents = res.data.documents;
    }
    this.chatService.sendMessage(request);
    this.messages.push(request);
    this.fileObjects = [];
    this.filePreview = [];
    this.messageText = '';
    this.commonService.emitValueForSpinner();
    this.commonService.scrollMsgArea();
  }

  get senderName() {
    return this.isAdmin
      ? this.adminDetails.name
      : this.userDetails.personal_info[0].name;
  }

  /**
   * Filter documents from chat
   */
  filterChatDocuments() {
    this.chatService.chatDocuments.subscribe((res: any) => {
      if (res.is_get_docs) {
        this.chatService.chatDocuments.next({
          is_get_docs: false,
          is_change_count: false,
        });
        this.showDocuments();
      }
      if (res.is_change_count) {
        let read_msg_id: any = [];
        this.messages.map(({ is_read, id, receiver_id }: any) => {
          if (!is_read && String(this.senderId) === String(receiver_id)) {
            read_msg_id.push(id);
          }
        });

        if (read_msg_id.length > 0) {
          this.saveChatMessage({ read_msg_id });
        }
      }
    });
  }

  /**
   * Show documents from chat
   */
  showDocuments() {
    let documents: any = {
      sent: [],
      received: [],
    };
    this.messages.forEach((ele: any) => {
      if (ele.documents) {
        if (
          (this.isAdmin && ele.sender_id === this.senderId) ||
          (this.isPatient && ele.receiver_id == this.receiverId)
        ) {
          documents.sent.push(...ele.documents);
        } else {
          documents.received.push(...ele.documents);
        }
      }
    });
    this.chatDocuments.emit(documents);
  }

  /**
   * Make video call between Admin and Patient
   * @param flag Admin Or ''
   * @param peerId peer Id
   */
  videoCall(flag = '', peerId?: any) {
    this.addUserInSocket();
    this.userDetails.flag = flag;
    this.userDetails.peerId = peerId;
    this.matDialog.open(VideoChatComponent, {
      width: '450px',
      height: 'auto',
      data: this.userDetails,
      disableClose: true,
    });
  }

  addUserInSocket() {
    let userId: any;
    if (this.isAdmin) {
      userId = this.adminDetails.admin_uid;
    } else if (this.isPatient) {
      userId = this.userDetails.id;
    }
    this.chatService.addUserInChat(userId);
  }

  /**
   * Download documents
   * @param documents files
   */
  downloadDocs(documents: any) {
    documents.forEach((item: string) => {
      this.commonService.downloadFile(item);
    });
  }

  /**
   * E-Sign modal open for sign docs
   * Only show for patient while status is 10
   * @param message message
   */
  eSignDocsModal(message: any = '') {
    if (!this.isPatient) {
      return;
    }
    const applicationLink = this.commonService.getHost() + `${RouteNames.MY_LOAN_APPLICATIONS}?loanId=${this.loanId}`;
    if (applicationLink && !message) {
      message = `Loan Agreement In Progress. Please sign the loan agreement. ${TermsCondition}`;
    }
    let loanSts = this.userDetails.loan_current_status;
    let title = this.loanStatus.filter(({ id }: any) => id === loanSts)[0]
      ?.title;
    let loanAgrmt = String(message).search(title) !== -1;
    if ((loanSts === LoanStatusId.LOAN_AGREEMENT_IN_PROGRESS || loanSts === LoanStatusId.DIGITAL_ESCROW_INITIATED) &&
      (loanAgrmt || applicationLink)) {
      this.openModalForESign(message);
    }
  }

  openModalForESign(message: any) {
    var url = String(message).match(Regex.getUrlFromString);
    if (!url) { return; }
    const dialogRef = this.matDialog.open(DigitalSignComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: { url: url[0], userDetails: this.userDetails },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.fileObjects = [result];
        this.sendMessage();
      }
    });
  }

  /**
   * Save docs of
   * Loan Agreement / Escrow Agreement
   * @param loan loan id
   */
  saveSignDocsURL(signDocsURL: string) {
    let request: any = {
      loan_id: this.userDetails.id,
    };

    switch (this.userDetails.loan_current_status) {
      case LoanStatusId.LOAN_AGREEMENT_IN_PROGRESS:
        request.signed_docs_url = signDocsURL;
        break;
      case LoanStatusId.DIGITAL_ESCROW_INITIATED:
        request.escrow_agrmt_signed = signDocsURL;
        break;
    }

    this.loanService.updateLoanApplication(request).subscribe((res: any) => { });
    this.commonService.emitValueForSpinner();
  }
}
